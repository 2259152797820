﻿import useSliderCategory from '../../hooks/useSliderCategory';
import { TileTypes } from '../../Components/Tiles/tiles';
import React from 'react';
import Slider, { SitecoreLink } from '../../Components/Sliders/Slider';
import ComponentLoader from '@SharedScripts/ComponentLoader';
import QueryState from '@SharedScripts/QueryState';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';

export type ExpandCategory = {
  expandCategoryId: string,
  title: string
}

interface SliderEntryProps {
  elementId: string;
  showTitle: boolean;
  categoryId: string;
  sectionName: string;
  sectionId: string;
  expandCategory?: ExpandCategory;
  expandLinkLocation?: SitecoreLink;
  type: Exclude<TileTypes, 'Mega'>
}

class SliderEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);
    this.init();
  }

  init() {
    const queryClient = new QueryState().load('shared');

    const ReactApp = (props: any) => {
      if (!props.section?.sectionTitle || !props.tileSize) {
        return <div></div>;
      }

      const sliderProps: SliderEntryProps = {
        elementId: props.elementId as string,
        categoryId: props.section.parentCategoryId as string,
        sectionName: props.section.sectionTitle as string,
        sectionId: props.section.sectionId as string,
        type: props.tileSize,
        showTitle: props.showTitle,
        expandLinkLocation: props.expandLinkLocation
      };

      if (props.section.expandCategory) {
        sliderProps.expandCategory = {
          expandCategoryId: props.section.expandCategory.expandCategoryId,
          title: props.section.expandCategory.title,
        };

      }

      return (
        <QueryClientProvider client={queryClient}>
          <SliderWrapper {...sliderProps} />
        </QueryClientProvider>
      );
    };

    const root = createRoot(this.element);
    root.render(<ReactApp {...this.settings} />);
  }
}

const SliderWrapper = ({ elementId, sectionName, categoryId, type, expandLinkLocation, expandCategory, sectionId, showTitle }: SliderEntryProps) => {
  const {
    games,
    isLoading,
    sectionName: sectionNameFromApi
  } = useSliderCategory(sectionName, categoryId, sectionId, elementId) ?? {};

  if (!isLoading && !games.length) return null;

  return <Slider
    showTitle={showTitle}
    expandCategory={expandCategory}
    expandLinkLocation={expandLinkLocation}
    type={type}
    sectionName={sectionNameFromApi}
    games={games}
    isLoading={isLoading}
  />;
};

export default SliderEntry;
