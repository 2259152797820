﻿import { useQuery, UseQueryResult, } from '@tanstack/react-query';
import {
  getGameSearch
} from '../api/CasinoApi';
import { useEffect, useState } from 'react';
import { Game } from '../apiTypes/GameService';

interface useSearchProps {
  searchQuery: string;
  searchDelay?: number
}

const useSearch = ({ searchQuery, searchDelay = 500 }: useSearchProps) => {
  const trimmedSearchQuery = searchQuery.trim().toLowerCase();
  const [enabled, setEnabled] = useState(false);

  const searchResult = useQuery({
    queryFn: () => getGameSearch(trimmedSearchQuery),
    queryKey: ['gameSearch', trimmedSearchQuery],
    enabled: enabled && trimmedSearchQuery.length > 1,
    staleTime: Infinity,
  });

  const [queryResponse, setQueryResponse] = useState<UseQueryResult<Game[] | undefined | Error>>();

  useEffect(() => {
    setEnabled(false);

    if (trimmedSearchQuery.length <= 1) {
      setEnabled(false);
      setQueryResponse(searchResult);
      return;
    }

    const timeout = setTimeout(() => {
      setEnabled(true);
    }, searchDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [trimmedSearchQuery]);

  useEffect(() => {
    if (!searchResult.isError && !searchResult.isPending) {
      setQueryResponse(searchResult);
      setEnabled(false);
    }
  }, [searchResult.data]);

  return queryResponse;
};

export default useSearch;