export const getTileStyle = (index: number) => {
  let className = index < 4 ? '' : 'hidden';
  if (index >= 4 && index < 8) {
    className += ' md:!grid is-desktop-detected:md:!hidden is-desktop-detected:lg:!grid';
  } else if (index >= 8 && index < 12) {
    className += ' lg:!grid is-desktop-detected:lg:!hidden is-desktop-detected:xl:!grid';
  }

  return className;
};

export const getTileBorder = () => 'after:absolute after:hidden after:md:block after:h-1 after:w-[calc(100%_-_24px)] after:left-12 after:bg-neutrals-coolGrey-300 after:bottom-10 md:after:-bottom-12 lg:after:-bottom-16';
