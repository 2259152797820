import React, { useState, useEffect } from 'react';

export const useSlide = (length: number) => {
  const [active, setActive] = useState(0);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);

  useEffect(() => {
    if (startX === 0 || endX === 0) return;
    const diff = startX - endX;
    if (diff > 50) {
      setActive((prev) => (prev >= length - 1 ? 0 : prev + 1));
    } else if (diff < -50) {
      setActive((prev) => (prev === 0 ? length - 1 : prev - 1));
    }
    setStartX(0);
    setEndX(0);
  }, [endX, length]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    setEndX(e.changedTouches[0].clientX);
  };

  return {
    active,
    setActive,
    handleTouchStart,
    handleTouchEnd,
  };
};

