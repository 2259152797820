﻿import ComponentLoader from '@SharedScripts/ComponentLoader';
import { pushGaEventOnPageRedirect } from '@Ensighten/Ensighten';

class NavigationTrackingEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);
    this.init();
  }

  init() {
    // Add tracking to navigation items
    const elements = this.settings.rootElement.querySelectorAll('a');
    const navigation = this.settings.rootElement.getAttribute('data-navigation');

    if (elements.length) {
      elements.forEach((element: HTMLElement) => {
        element.addEventListener('click', (ev: MouseEvent) => {
          const category = 'navigation';
          const target = ev.target as HTMLElement;
          const label = target ? target.innerText : '';
          const action = 'menu click ' + navigation;
          pushGaEventOnPageRedirect({ category, label, action });
        });
      });
    }
  }
}

export default NavigationTrackingEntry;
