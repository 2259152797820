﻿import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Backdrop } from '../Backdrop/Backdrop';
import { Header } from '../Header/Header';
import { DynamicTile } from '../Tiles/DynamicTile';
import { TileProps, TileTypesEnum } from '../Tiles/tiles';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Panel from '../../../assets/icons/svg/Panel';
import { Icon } from '../Icon/Icon';
import useFilter from '../../hooks/useFilter';
import Listview from '../../../assets/icons/svg/Listview';
import Gridview from '../../../assets/icons/svg/Gridview';
import Sort from '../../../assets/icons/svg/Sort';
import SortingDropdown from './SortingDropdown';
import { ContainerEntryProps } from '../../modules/CategoryList/CategoryList.entry';
import Filter from '../FormElements/Filter';
import { getParameter } from '@SharedScripts/Utils/Url';
import Pagination from './Pagination';
import { isMobile, isDesktop } from '@SharedScripts/Utils/DeviceClass';
import { deepCopyArray } from '@SharedScripts/Utils/List';
import useBreakpoint from '@SharedScripts/Hooks/useBreakpoint';
import { useMedia } from '@SharedScripts/Hooks/useMediaQuery';
import { track } from '../../helpers/tracking';

const sortingOptions = ['Mest populære', 'Alfabetisk', 'Nyeste', 'Indskud, høj til lav', 'Indskud, lav til høj'];

const CategoryList = ({ categoryName, showAsList, categoryId, defaultSort, isEnabledCuratedLayout = false }: ContainerEntryProps) => {
  const filterResult = useFilter(categoryId);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [page, setPage] = useState(Number(getParameter('page')) || 1);
  const [showPreviousPages, setShowPreviousPages] = useState(true);
  const pageSize = 30;
  const breakpoint = useBreakpoint();
  const { isMd, isLg, isXl } = useMedia();
  const mobileCategoryRendering = isMobile() && isEnabledCuratedLayout;

  const [selectedSort, setSelectedSort] = useState<string>(sortingOptions.includes(defaultSort?.name || '') ? defaultSort.name : sortingOptions[0]);

  const handleSortChange = (_: string, newSort: string) => {
    setPage(1);
    setSelectedSort(newSort);
    setIsSortOpen(false);
  };

  const getStartingPoint = () => {
    if (showPreviousPages) return 0;

    return (page * pageSize) - pageSize;
  };
  const getShowCount = () => page * pageSize;

  const sortGames = useCallback((a: TileProps, b: TileProps) => {
    if (selectedSort === 'Alfabetisk') return a.title?.localeCompare(b.title || '') ?? -1;
    if (selectedSort === 'Nyeste' && a.releaseDate && b.releaseDate) return b.releaseDate.getTime() - a.releaseDate.getTime();
    if (selectedSort === 'Mest populære' && typeof a.popscore === 'number' && typeof b.popscore === 'number') return b.popscore - a.popscore;
    if (selectedSort === 'Indskud, lav til høj' && typeof a.minWagerRaw === 'number' && typeof b.minWagerRaw === 'number') return a.minWagerRaw - b.minWagerRaw;
    if (selectedSort === 'Indskud, høj til lav' && typeof a.minWagerRaw === 'number' && typeof b.minWagerRaw === 'number') return b.minWagerRaw - a.minWagerRaw;

    return 1;
  }, [selectedSort]);

  const sortedGames = useMemo(() => {
    return deepCopyArray(filterResult.matchingGames || []).sort(sortGames);
  }, [filterResult.matchingGames, sortGames]);

  const displayedGames = useMemo(() => {
    return sortedGames.slice(getStartingPoint(), getShowCount());
  }, [sortedGames, page]);

  const [selectedViewType, setSelectedViewType] = useState<'list' | 'grid'>(showAsList ? 'list' : 'grid');

  const { isVisible: isTriggerVisible, elementRef } = useIntersectionObserver(true, 0);

  const [gamesShowing, setGamesShowing] = useState(filterResult.matchingGames.sort(sortGames).slice(getStartingPoint(), getShowCount()));

  useEffect(() => {
    if (isTriggerVisible && filterResult.matchingGames.length && showPreviousPages && gamesShowing.every((game) => !game.isLoading)) {
      setPage(page + 1);
    }
  }, [isTriggerVisible, gamesShowing.every((game) => !game.isLoading)]);

  useEffect(() => {
    setGamesShowing(displayedGames);
  }, [displayedGames]);

  useEffect(() => {
    const pageCount = getParameter('page');
    if (pageCount) {
      setShowPreviousPages(false);
    }
  }, []);

  const handleOverlayHide = () => {
    setIsSortOpen(false);
  };

  useEffect(() => {
    document.addEventListener('overlay:modal:hide', handleOverlayHide);

    return () => {
      document.removeEventListener('overlay:modal:hide', handleOverlayHide);
    };
  }, []);

  const sortingDropdown = useRef<HTMLButtonElement>(null);
  const handleClickOutside = (event: MouseEvent) => isDesktop() && isSortOpen && !sortingDropdown.current?.contains(event.target as HTMLButtonElement) && handleOverlayHide();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSortOpen]);

  const columnStyle = selectedViewType === 'grid' ? 'md:grid-cols-4 md:is-desktop-detected:grid-cols-4 xl:!grid-cols-6' : 'md:grid-cols-2 md:is-desktop-detected:grid-cols-1 lg:!grid-cols-2 xl:!grid-cols-3';
  const firstGame = [...displayedGames][0];
  const backdropSrc = [...displayedGames][0]?.gameGraphics?.backgroundUrl ?? '';

  const dispatchFilterEvent = () => {
    document.dispatchEvent(new CustomEvent('casino:filter:open'));
  };

  return (
    <section className={'w-full mx-auto block pb-32'}
      data-tracking-meta-data={JSON.stringify({
        category: categoryName,
      })}
    >
      <Filter {...filterResult} />

      {(backdropSrc && (isMd || mobileCategoryRendering)) && <div
        className={'relative'}
      >
        <div className={`absolute w-[calc(100vw_+_3rem)] left-1/2 -translate-y-96 -translate-x-1/2 ${isMd ? 'is-tablet-detected:-top-48 is-desktop-detected:top-0' : mobileCategoryRendering ? 'is-mobile-detected:-top-48' : ''}  overflow-hidden h-400`}>
          <Backdrop src={backdropSrc}
            className={`${isMd ? 'is-desktop-detected:!h-[calc(100%_+_1rem)] is-desktop-detected:!left-[calc(var(--sidemenu-width)_/_2)] is-desktop-detected:!w-[calc(100%_-_var(--sidemenu-width)_+_2.5rem)] is-desktop-detected:!transform-none' : ''}`}
            gradient={false} blur={true}
          />
        </div>
        {mobileCategoryRendering &&
        <Header
          as='h1'
          size='xl'
          className='relative mb-16 text-neutrals-white font-base font-semibold line-clamp-2'
        >
          {categoryName ?? ''}
        </Header>}
      </div>}

      <div className={`relative w-full flex ${mobileCategoryRendering ? 'flex-col mb-32' : 'flex-col-reverse mb-8'} md:flex-row justify-between md:mb-48 gap-24 md:gap-0`}>
        {mobileCategoryRendering &&
        <ul className='grid list-none grid-cols-1 gap-x-16 gap-y-0 mb-32'>
          <li key={`categorylist-${firstGame?.id}`}
            className='relative size-full min-h-96'
            data-tracking-meta-data={JSON.stringify({
              value: 1,
              gameTitle: firstGame?.title,
            })}
          >
            <DynamicTile
              type={TileTypesEnum.LARGE}
              data={{ ...firstGame, isLoading: filterResult.isPending }}
              className='size-full'
            />
          </li>
        </ul>}

        {!mobileCategoryRendering &&
        <Header
          as='h1'
          size='base'
          className='!text-18 md:!text-32 lg:!text-48 text-neutrals-grey-950 md:text-neutrals-white font-base font-semibold md:font-bold line-clamp-2'
        >
          {categoryName ?? ''}
        </Header>}

        <div className={'grid text-center grid-cols-2 md:grid-cols-[repeat(3,_auto)] gap-12 max-h-40'}>
          {filterResult.isFiltersAvailable && <button onClick={dispatchFilterEvent}
            aria-label={'Åben filtreringsmuligheder'}
            className={'bg-white px-16 py-8 text-neutrals-grey-950 flex justify-center items-center gap-8 text-12 font-extrabold tracking-wide rounded-full border-none hover:bg-neutrals-coolGrey-50 duration-100'}
          >FILTER{filterResult.totalFiltersSelected ? ` (${filterResult.totalFiltersSelected})` : ''}
            <Icon size={'small'} icon={<Panel/>}/>
          </button>
          }

          <button
            className={`bg-white relative text-center px-16 py-8 text-neutrals-grey-950 flex justify-center items-center gap-8 text-12 font-extrabold tracking-wide rounded-full border-none hover:bg-neutrals-coolGrey-50 duration-100 ${isSortOpen ? '!bg-gold-ultraLight' : ''} ${!filterResult.isFiltersAvailable ? 'col-start-2' : ''}`}
            onClick={() => setIsSortOpen(!isSortOpen)}
            aria-label={'Åben sorteringsmuligheder'}
            ref={sortingDropdown}
          >SORTERING <SortingDropdown categoryName={categoryName} sortingOptions={sortingOptions} isOpen={isSortOpen} selectedSort={selectedSort}
              handleSortChange={handleSortChange}
            />
            <Icon size={'medium'} icon={<Sort/>}/>
          </button>
          <div className={'hidden gap-8 md:flex'}>

            <button
              className={`border-none ${selectedViewType === 'list' ? '!bg-gold-ultraLight' : 'bg-white'} p-0 size-40 rounded-8 hover:bg-neutrals-coolGrey-50 duration-100`}
              onClick={
                (e) => {
                  setSelectedViewType('list');
                  setPage(1);
                  setIsSortOpen(false);
                  track(e);
                }
              }
              data-tracking-meta-data={JSON.stringify({
                action: 'list view click',
                label: 'list',
              })}
            ><Icon size={'medium'} icon={<Listview/>}/></button>
            <button
              className={`border-none ${selectedViewType === 'grid' ? '!bg-gold-ultraLight' : 'bg-white'} p-0 size-40 rounded-8 hover:bg-neutrals-coolGrey-50 duration-100`}
              onClick={
                (e) => {
                  setSelectedViewType('grid');
                  setPage(1);
                  setIsSortOpen(false);
                  track(e);
                }
              }
              data-tracking-meta-data={JSON.stringify({
                action: 'grid view click',
                label: 'grid',
              })}
            ><Icon size={'medium'} icon={<Gridview/>}/></button>
          </div>
        </div>
      </div>

      <ul
        className={`grid list-none grid-cols-1 gap-x-16 md:gap-y-40 ${selectedViewType === 'grid' ? 'gap-y-0 md:gap-y-16' : 'gap-y-0'} ${columnStyle}`}
      >
        {gamesShowing.map((tileProps, i) => {
          if (mobileCategoryRendering && i === 0) return;
          let isFirstRow = false;

          if (isXl && i < 3) {
            isFirstRow = true;
          } else if (isDesktop() && isLg && i < 2) {
            isFirstRow = true;
          } else if (isDesktop() && isMd && i < 2) {
            isFirstRow = (selectedViewType === 'list' && i === 0) || selectedViewType === 'grid';
          } else if (!isDesktop() && isMd && i < 2) {
            isFirstRow = true;
          }

          const tileType = isFirstRow ? TileTypesEnum.LARGE : (selectedViewType === 'grid' && isMd) ? TileTypesEnum.MEDIUM : TileTypesEnum.SMALL;

          const firstRowSpan = (isFirstRow && selectedViewType === 'grid' && isMd) ? 'col-span-1 md:col-span-2' : '';

          const smallTileBorderStyle = (!isFirstRow && selectedViewType === 'list' && (breakpoint !== 'xs' && breakpoint !== 'sm')) ? 'after:absolute after:h-1 after:w-[calc(100%_-_24px)] after:left-12 after:bg-neutrals-coolGrey-300 after:bottom-10 md:after:-bottom-16' : '';

          let extraSpace = '';
          if  (selectedViewType === 'list') {
            if (!isDesktop()) {
              if ((breakpoint === 'md' || breakpoint === 'lg') && i > 1) {
                if (i % 2 === 0) {
                  extraSpace = 'pr-12';
                } else if (i % 2 === 1) {
                  extraSpace = 'ml-12 pr-12';
                }
              } else if ((breakpoint === 'xl' || breakpoint === '2xl') && i > 2) {
                if (i % 3 === 0) {
                  extraSpace = 'pr-12';
                } else if (i % 3 === 1) {
                  extraSpace = 'pr-12 ml-12';
                } else if (i % 3 === 2) {
                  extraSpace = 'ml-12 pr-12';
                }
              }
            }
          }

          return (
            <li key={`categorylist-${tileProps.id}`}
              className={`relative size-full min-h-96 ${firstRowSpan} ${smallTileBorderStyle} ${extraSpace}`}
              data-tracking-meta-data={JSON.stringify({
                value: i + 1,
                gameTitle: tileProps.title,
              })}
            >
              <DynamicTile
                type={tileType}
                data={{ ...tileProps, isLoading: filterResult.isPending }}
                className='size-full'
              />
            </li>
          );
        })}
      </ul>

      {/* Load more scroll trigger */}
      <div className={'w-full h-1 bg-transparent relative -top-432'} ref={elementRef}></div>
      <Pagination currentPage={page} pages={Math.ceil(filterResult.matchingGames.length / pageSize)}/>
    </section>
  );
};

export default CategoryList;
