﻿import React from 'react';
import { useQuery } from '@tanstack/react-query';
import BlackJackCards from '../../../assets/icons/svg/BlackJackCards';
import BlackJackText from '../../../assets/icons/svg/BlackJackText';
import useLiveData from '../../hooks/useLiveData';
import { Header } from '../Header/Header';
import { IconButton } from '../IconButton/IconButton';
import { useMedia } from '@SharedScripts/Hooks/useMediaQuery';
import { getGame } from '../../api/CasinoApi';
import { getParameter } from '@SharedScripts/Utils/Url';

export type ContainerProps = {
  gameServiceId: string
  type: 'Live'| 'Casino'
}

// const getGameUrl = (game: Game | undefined) => `${location.pathname.split('/')[1]}/spil/${encodeURIComponent(game?.title ?? '')}`;

const BlackJackSeatSelector = ({ gameServiceId }: ContainerProps) => {
  const { data } = useQuery({
    queryKey: ['getGame', gameServiceId],
    queryFn: () => getGame(gameServiceId)
  });

  const gameData = useLiveData(data ? [data] : []);
  const { isMd } = useMedia();
  const tableData = gameData[0] ? gameData[0].tableData : undefined;
  if (tableData === undefined || tableData.tableType !== 'Blackjack') return;
  if (!tableData?.open) return;
  const totalSeats = new Array(tableData.totalSeats ?? 0).fill({});
  const parameterAutoLaunch = Boolean(getParameter('launch')) ;

  const seatSelectorEvent = (seat : number) => {
    document.dispatchEvent(new CustomEvent('casino:gamelaunch:seatselector', { detail: { context: { seat: seat } } }));
  };

  if (parameterAutoLaunch) {
    return <></>;
  }

  return (
    <section className='cw-blackjack-seats'>
      <div className='text-center mb-20'>
        <Header
          as='h1'
          size='base'
          className='italic font-extrabold'>
          {tableData.open && !!totalSeats.length && totalSeats.length !== tableData.playerSeated?.length ? 'Vælg hvor du vil sidde' : 'Der er ingen ledige pladser'}
        </Header>
      </div>

      <div className='grid grid-cols-1'>
        <div className='relative block z-10 left-1/2 cw-blackjack-semicircle--outer'>
          <div className='absolute block z-10 left-1/2 bg-gold-dark cw-blackjack-semicircle--inner'>
            <div className={`absolute grid grid-cols-1 ${isMd ? 'gap-10' : 'gap-4'} z-10 left-1/2 cw-blackjack-seatlogo`}>
              <BlackJackText className='cw-blackjack-seatlogo--text' />
              <BlackJackCards className='cw-blackjack-seatlogo--cards' />
            </div>
          </div>
          <ul className='m-0 fill-white'>
            {
              totalSeats.map((_ignore, i) => <li key={i} className={`relative inline-block cw-blackjack-seat-${i + 1}`}>
                {tableData.playerSeated?.includes(i)
                  ? <IconButton action={() => { }} size={isMd ? 'large' : 'small'} variant='seat' disabled className='cw-blackjack-seaticon cw-blackjack-seaticon--inactive' />
                  : <IconButton action={() => seatSelectorEvent(i)} size={isMd ? 'large' : 'small'} variant='seat' className='cw-blackjack-seaticon cw-blackjack-seaticon--active' />
                }</li>)
            }
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BlackJackSeatSelector;
