import React from 'react';
import Slider from '../../Components/Sliders/Slider';
import useSliderRelated from '../../hooks/useSliderRelated';
import { TileTypesEnum } from '../../Components/Tiles/tiles';
import { GAMES_RELATED } from '../../helpers/tileHelper';

export type RelatedTileSliderEntryProps = {
  relatedToGameId: string,
}

const RelatedTileSlider = ({ relatedToGameId }: RelatedTileSliderEntryProps) => {
  const { games, isPendingOrRefetching } = useSliderRelated(relatedToGameId) ?? {};

  return <Slider
    type={TileTypesEnum.MEDIUM}
    sectionName={GAMES_RELATED}
    isLoading={isPendingOrRefetching}
    games={games}
  />;
};

export default RelatedTileSlider;
