﻿import React from 'react';
import Trophy from '../../../assets/animations/trophy.json';
import { Header } from '../Header/Header';
import { TileTypesEnum, type TileProps } from '../Tiles/tiles';
import { getLoadingTiles } from '../../helpers/tileHelper';
import { DynamicTile } from '../Tiles/DynamicTile';
import { LottieAnimation } from '../Animation/LottieAnimation';
import { PotOfGoldProps } from '../../Types';

export type ContainerProps = PotOfGoldProps & {
  title: string,
  winners: TileProps[];
  amount: number;
  className?: string;
  isLoading?: boolean;
}

const WinnersList = ({ title, winners, amount, isPotOfGold, isLoading }: ContainerProps) => {
  const showLoadingState = isLoading && (!winners || winners?.length === 0);
  if (showLoadingState) winners = getLoadingTiles(amount);

  return winners?.length > 0 && (
    <div className='overflow-hidden relative -left-[--grid-margin] w-screen is-desktop-detected:left-0 is-desktop-detected:w-full'>
      <div className={`block absolute text-center left-1/2 cw-winnerslist-top ${isPotOfGold ? 'cw-winnerslist-top--purple' : 'cw-winnerslist-top--gold'}`}>
        <LottieAnimation animationData={Trophy} className='w-200 mt-12' loop />
      </div>

      <section className={`${isPotOfGold ? 'bg-purple-primary' : 'bg-gold-primary'} rounded-t-32 sm:rounded-32 mt-112 mx-auto block px-12 is-desktop-detected:px-40 pt-32 pb-24 md:mb-10`}>
        <Header
          as={'h1'}
          size={'base'}
          className='mb-20 text-white italic text-center text-28'>
          {title ?? ''}
        </Header>

        <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 is-desktop-detected:lg:grid-cols-2 is-desktop-detected:xl:grid-cols-3'} data-tracking-meta-data={JSON.stringify({
          action: title ?? '',
          category: 'guldpotten'
        })}>
          {winners.map((winner, index) => {
            return (
              <div key={`${winner.id}-${index}`} className='relative md:px-8 md:pb-32 md:pt-12 w-full flex flex-col text-white fill-neutrals-white'>
                <DynamicTile
                  key={winner.id}
                  type={TileTypesEnum.WINNER}
                  data={{ ...winner, isLoading }} />
              </div>);
          })}
        </div>
      </section>
    </div>
  );
};

export default WinnersList;
