﻿import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import RadioGroup from './RadioGroup';
import Checkbox from './Checkbox';
import Toggle from './Toggle';
import VolatilitySelector, { Volatility } from './VolatilitySelector';
import { Button } from '../Button/Button';
import ChevronUp from '../../../assets/icons/svg/ChevronUp';
import ChevronDown from '../../../assets/icons/svg/ChevronDown';
import Close from '../../../assets/icons/svg/Close';
import { Icon } from '../Icon/Icon';
import { filterTypeMap, SelectedFilterProps, FilterProps } from '../../hooks/useFilter';
import { Header } from '../Header/Header';
import Drawer from '../Drawer';
import { TileProps } from '../Tiles/tiles';
import { createPortal } from 'react-dom';

interface FilterComponentProps {
  filters: FilterProps[]
  selectedFilters: Record<keyof typeof filterTypeMap, SelectedFilterProps>
  setSelectedFilters: Dispatch<SetStateAction<Record<keyof typeof filterTypeMap, SelectedFilterProps>>>
  allSelectedAsList: string[]
  removeSelectedFilters: () => void
  categoryName: string
  totalFiltersSelected: number;
  matchingGames: TileProps[];
}

const Filter = ({ filters, selectedFilters, setSelectedFilters, removeSelectedFilters, categoryName, totalFiltersSelected, matchingGames, allSelectedAsList }: FilterComponentProps) => {
  const [expandedFilters, setExpandedFilters] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const filterComponent = useRef<HTMLDivElement>(null);
  const isDesktop = () => Boolean(document.querySelector('.is-desktop-detected'));


  const dsOverlay = document.querySelector('.ds-overlay');

  useEffect(() => {
    document.addEventListener('casino:filter:open', handleOpen);
    if (dsOverlay) {
      dsOverlay.addEventListener('mousedown', handleClose, true);
    }

    return () => {
      document.removeEventListener('casino:filter:open', handleOpen);
      if (dsOverlay) {
        dsOverlay.removeEventListener('mousedown', handleClose, true);
      }
    };
  }, [showFilters]);


  const handleOpen = () => {
    document.dispatchEvent(new CustomEvent('overlay:modal:show'));
    setShowFilters(true);
  };

  const handleClose = () => {
    document.dispatchEvent(new CustomEvent('overlay:modal:hide'));
    setShowFilters(false);
  };

  const toggleRadio = (name: keyof typeof filterTypeMap | 'Sorting', value: string) => {
    if (name === 'Sorting') return;
    const updated = {
      ...selectedFilters,
      [name]: {
        selected: selectedFilters[name]?.selected.includes(value) ? [] : [value]
      }
    };
    setSelectedFilters(updated);
  };

  const getToggleLabel = (filterName: string) => {
    if (filterName === 'Jackpot') return 'Vis kun spil med jackpot';
    if (filterName === 'DanishDealer') return 'Vis kun Danske Spil borde';
    if (filterName === 'TableStatus') return 'Vis kun åbne borde';
    return '';
  };

  const toggleExpandedFilter = (filterName: string) => {
    let expandedFiltersCopy = [...expandedFilters];
    if (expandedFilters.includes(filterName)) {
      expandedFiltersCopy = expandedFiltersCopy.filter((name) => name !== filterName);
    } else {
      expandedFiltersCopy.push(filterName);
    }

    setExpandedFilters(expandedFiltersCopy);
  };

  const toggleCheckbox = (name: keyof typeof filterTypeMap, value: string) => {
    const isAlreadySelected = selectedFilters[name].selected.includes(value);
    let newSelected = [...selectedFilters[name].selected];

    if (isAlreadySelected) {
      newSelected = newSelected.filter((selected: string) => selected !== value);
    } else {
      newSelected.push(value);
    }

    setSelectedFilters({
      ...selectedFilters,
      [name]: {
        selected: newSelected
      }
    });
  };

  const handleVolatilityChange = (newVolatility: Volatility) => {
    const selectedFiltersCopy = { ...selectedFilters };
    if (newVolatility === 'None') {
      selectedFiltersCopy.Volatility.selected = [];
    } else {
      selectedFiltersCopy.Volatility.selected = [newVolatility];
    }
    setSelectedFilters(selectedFiltersCopy);
  };

  const sortedNumberFilters = (filterValues: string[]) => (filterValues.every((value) => /^\d+(\.\d{1,2})?$/.test(value))
    ? filterValues.sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
    : filterValues);

  const filterDom = (
    <div data-tracking-meta-data={JSON.stringify({ action: 'filter', label: allSelectedAsList })} aria-hidden={showFilters} ref={filterComponent} className={`theme-casino bg-white is-desktop-detected:max-w-512 is-desktop-detected:w-full is-desktop-detected:fixed is-desktop-detected:z-10000
     is-desktop-detected:px-28 is-desktop-detected:pt-48 is-desktop-detected:duration-500 is-desktop-detected:overflow-auto
    is-desktop-detected:rounded-none is-desktop-detected:top-0 is-desktop-detected:right-0 is-desktop-detected:left-[unset] is-desktop-detected:translate-x-0 is-desktop-detected:h-full
    ${showFilters ? 'is-desktop-detected:translate-x-0' : 'is-desktop-detected:translate-x-full'}`}
    >

      <Header as={'h1'} size={'sm'} className={'pl-12'}>Filtrér på {categoryName}</Header>
      <button onClick={handleClose}
        className={'sticky z-10 mr-8 float-right -translate-y-full inline-block top-0 p-0 size-32 rounded-full border-none bg-neutrals-grey-200'}
      ><Icon icon={<Close/>} size={'medium'} className={'*:stroke-black'}/></button>

      {!filters.length && 'Loading...'}
      {filters.length &&
        filters.map((filter) => {

          return (<div
            data-tracking-meta-data={JSON.stringify({ category: filter.name })}
            className={'relative first:mt-0 mt-48 first:before:hidden before:block before:h-1 before:w-[calc(100%_-_24px)] before:left-12 before:bg-neutrals-coolGrey-100 before:absolute before:-top-24'}
            key={filter.name}
          >

            <div className={'px-12 font-bold text-20 mb-24'}>{filter.title}</div>
            {
              (filter.type === 'radio' || filter.type === 'filterChip') && filter.values && filter.name &&
              <div className={'px-12'}>
                <RadioGroup
                  variant={filter.type}
                  filters={sortedNumberFilters(filter.values)}
                  name={filter.name as keyof typeof filterTypeMap}
                  selectedOption={selectedFilters[filter.name as keyof typeof filterTypeMap].selected.at(0) || ''}
                  onValueChange={toggleRadio}
                />
              </div>
            }
            {
              filter.type === 'checkbox' && filter.values && filter.name && (
                <>
                  <div
                    className={`grid min-h-150 duration-500 ease-out ${expandedFilters.includes(filter.name) || filter.values.length <= 3 ? 'grid-rows-1' : 'grid-rows-0'}`}
                  >
                    <div className={'overflow-hidden min-h-[inherit]'}>

                      {
                        filter.values.map((checkboxFilter) => {
                          return <Checkbox
                            key={checkboxFilter}
                            value={checkboxFilter}
                            name={filter.name as keyof typeof filterTypeMap}
                            checked={selectedFilters[filter.name as keyof typeof filterTypeMap].selected.includes(checkboxFilter)}
                            onClick={toggleCheckbox}
                          />;
                        })
                      }
                    </div>
                  </div>
                  {filter.values.length > 3 && (
                    <button
                      className={'text-14 font-bold px-12 pt-12 m-0 mt-12 bg-transparent border-none flex justify-between items-end h-32 text-[inherit]'}
                      onClick={() => toggleExpandedFilter(filter.name as string)}
                    >Vis {expandedFilters.includes(filter.name) ? 'færre' : 'flere'} <Icon
                        className={'translate-y-6'}
                        size={'large'} icon={expandedFilters.includes(filter.name) ? <ChevronUp/> : <ChevronDown/>}
                      /></button>
                  )}
                </>
              )
            }
            {
              filter.type === 'toggle' && filter.name && filter.title &&
              <Toggle
                showLabel={true}
                label={getToggleLabel(filter.name)}
                checked={selectedFilters[filter.name as keyof typeof filterTypeMap].selected.includes(filter.name)}
                title={filter.title}
                name={filter.name as keyof typeof filterTypeMap}
                onClick={toggleCheckbox}
              />
            }
            {
              filter.type === 'volatilitySelector' && filter.name === 'Volatility' &&
              <VolatilitySelector
                onDotClick={handleVolatilityChange}
                volatility={selectedFilters[filter.name].selected[0] as Volatility || 'None'}
              />
            }
          </div>);
        })
      }

      <div
        className={'sticky bottom-0 grid grid-cols-2 py-16 gap-x-16 bg-white z-10 before:h-1 before:w-full before:bg-neutrals-coolGrey-100 before:absolute before:top-0'}
      >
        <Button onClick={() => {
          removeSelectedFilters();
        }} text={'Ryd alle'} variant={'text'} size={'small'} className={`!normal-case ${!totalFiltersSelected ? 'pointer-events-none opacity-50' : ''}`}
        ></Button>
        <Button variant={'primary'} size={'medium'} onClick={handleClose} text={`VIS SPIL (${matchingGames.length})`}
        ></Button>
      </div>
    </div>
  );

  if (isDesktop()) {
    return createPortal(filterDom, document.body);
  }

  return <Drawer isOpen={showFilters}>
    {filterDom}
  </Drawer>;
};

export default Filter;
