import { useEffect, useState, useRef } from 'react';

const useIntersectionObserver = (persist = false, threshold = 0.1) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observerCallback = ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        if (!persist) observer.unobserve(entry.target);
      } else {
        setIsVisible(false);
      }
    };

    const observerOptions = {
      threshold,
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [threshold]);

  return { isVisible, elementRef };
};

export default useIntersectionObserver;
