﻿import React from 'react';
import { Icon } from '../Icon/Icon';
import SadHeart from '../../../assets/icons/svg/SadHeart';
import { Header } from '../Header/Header';
import { Button } from '../Button/Button';
import { buttonVariants, baseStyle, buttonSizes } from '../Button/Button';
import { getLoginUrl } from '@SharedScripts/UserData/Authentication';
import { track } from '../../helpers/tracking';

interface MissingDataProps {
  type: 'notLoggedIn' | 'noFavorites',
  className?: string,
  customEvent?: CustomEvent
}

const createAccountUrl = `/blaa-konto/opret-konto?signupSuccessUrl=${window.location.href}`;

const MissingData = ({ type, className = '', customEvent }: MissingDataProps) => {
  let headerText = '';
  let buttonText = '';
  let ctaAction: React.ReactNode = undefined;

  if (type === 'noFavorites') {
    headerText = 'Du har endnu ingen favoritter';
    buttonText = 'TILFØJ FAVORITTER';
    ctaAction = <div className='inline-flex' data-tracking-meta-data={JSON.stringify({
      action: 'launch add to favorite',
      category: 'dine favoritter',
      label: buttonText,
    })} onClick={track}>
      <Button
        text={buttonText}
        onClick={() => {
          if (customEvent) document.dispatchEvent(customEvent);
        }}
        size={'large'}
        className={'mb-24 *:!text-12'}
      />
    </div>;
  } else if (type === 'notLoggedIn') {
    headerText = 'Log ind eller opret en konto for at tilføje favoritter';
    buttonText = 'LOG IND';
    ctaAction = <div className='inline-flex' data-tracking-meta-data={JSON.stringify({
      action: 'login cta click',
      category: 'dine favoritter',
      label: buttonText,
    })} onClick={track}>
      <a
        href={getLoginUrl({})}
        className={`${baseStyle} ${buttonSizes.large} ${buttonVariants.primary} tracking-wider mb-24 min-w-165 text-center`}>
        {buttonText}
      </a>
    </div>;
  }

  return (
    <div className={`flex flex-col items-center mb-52 pb-32 max-w-256 ${type === 'noFavorites' ? 'md:mb-0 md:pb-0' : ''} ${className}`}>

      <Icon icon={<SadHeart/>}/>
      <Header
        className={'text-center mb-16'}
        size={'xs'}
        as={'h1'}>{headerText}</Header>
      {ctaAction}
      {type === 'notLoggedIn' && <div>
        <p className={'text-14 text-neutrals-grey-500 m-0'}>Har du ingen konto?</p>
        <a
          className={'bg-transparent border-none underline text-14 text-neutrals-grey-950'}
          href={createAccountUrl}
          data-tracking-meta-data={JSON.stringify({
            action: 'create account cta click',
            category: 'dine favoritter',
            label: 'Opret en konto her',
          })}
          onClick={track}
        >Opret en konto her</a>
      </div>
      }
    </div>);
};

export default MissingData;
