﻿import React, { useEffect, useState } from 'react';
import { Icon } from '../Icon/Icon';
import Trash from '../../../assets/icons/svg/Trash';
import SearchV2 from '../../../assets/icons/svg/SearchV2';
import { CategorySectionTileType, Game } from '../../apiTypes/GameService';
import { Header } from '../Header/Header';
import { DynamicTile } from '../Tiles/DynamicTile';
import { searchGameData } from '../../helpers/dataAdapter';
import { SearchContext } from '../../Types';
import { UseSearchInteractionReturnType } from '../../hooks/useSearchInteraction';

interface SearchResultProps {
  header: string;
  displayCount?: boolean;
  searchResults: Game[];
  searchContext?: SearchContext;
  type: 'recent' | 'popular' | 'results' | 'noResult';
  searchInteractions: UseSearchInteractionReturnType;
  open: boolean;
}

const SearchResult = ({
  header,
  searchContext = 'normal',
  searchResults,
  displayCount = false,
  type,
  searchInteractions,
  open = false
}: SearchResultProps) => {
  const [isMobileWidth, setIsMobileWidth] = useState(
    matchMedia('(max-width: 640px)').matches,
  );

  const {
    addSearchInteraction,
    removeSearchInteraction
  } = searchInteractions;

  const adaptedGames = searchGameData(searchResults, searchContext === 'superFavorite');

  useEffect(() => {
    const setWidthDevice = () => {
      setIsMobileWidth(matchMedia('(max-width: 640px)').matches);
    };

    window.addEventListener('resize', setWidthDevice, true);

    return () => {
      window.removeEventListener('resize', setWidthDevice, true);
    };
  }, []);

  return open ? (
    <div className={'flex flex-col gap-y-12 p-0'} data-tracking-meta-data={JSON.stringify({ searchType: type })}>
      <div className={'flex justify-between items-center'}>
        <Header
          as={'h1'}
          size={'sm'}
        >{`${header}${displayCount ? ` (${searchResults.length})` : ''}`}</Header>
        {type === 'recent' && (
          <button
            className={
              'flex border-none bg-transparent text-12 font-bold text-neutrals-grey-950'
            }
            onClick={() => removeSearchInteraction?.mutate()}
          >
            Ryd seneste{' '}
            <Icon
              size={'small'}
              className={'ml-4'}
              icon={<Trash />} />
          </button>
        )}
      </div>
      <div className={`flex flex-wrap px-2 ${type === 'noResult' ? 'justify-center lg:justify-start' : ''} mb-0 sm:gap-[--grid-gutter] ${searchContext === 'superFavorite' ? 'sm:gap-y-44' : 'sm:gap-y-32'} gap-y-16 *:w-full sm:*:w-auto`}>
        {type === 'noResult' && <Icon icon={<SearchV2 />} className='w-80 h-80 lg:w-auto lg:h-auto' />}
        {adaptedGames.map((game) => {

          return (
            <DynamicTile
              disableLazyLoad={true}
              type={
                isMobileWidth
                  ? CategorySectionTileType.SMALL
                  : CategorySectionTileType.MEDIUM
              }
              className={
                `w-[--grid-columns-12] sm:w-[--grid-columns-6] md:w-[--grid-columns-4] lg:w-[--grid-columns-3] xl:w-[--grid-columns-2] ${searchContext === 'superFavorite' ? '[&_button]:bg-yellow-primary [&_button:hover]:!bg-yellow-light [&_button]:text-neutrals-grey-950' : ''}`
              }
              key={game.id}
              data={{ ...game, addToSearchInteractions: () => game.id && addSearchInteraction?.mutate(game.id) }}
            />
          );
        })}
      </div>
    </div>
  ) : null;
};

export default SearchResult;
