﻿import React, { useEffect, useRef, useState } from 'react';
import { default as SearchIcon } from '../../../assets/icons/svg/Search';
import Close from '../../../assets/icons/svg/Close';
import { Icon } from '../../Components/Icon/Icon';
import { useQuery } from '@tanstack/react-query';
import { getPopularSearches, getRecentlyPlayed } from '../../api/CasinoApi';
import useSearch from '../../hooks/useSearch';
import SearchResult from '../../Components/Search/SearchResult';
import { Game } from '../../apiTypes/GameService';
import useMimicNavigationAnimation from '../../hooks/useMimicNavigationAnimation';
import useSearchInteraction from '../../hooks/useSearchInteraction';
import { track } from '../../helpers/tracking';
import { SearchContext } from '../../Types';
import { SearchInitStateSessionKey } from '../../Constants';
import useIsLoggedIn from '@SharedScripts/Hooks/useIsLoggedIn';

export interface SearchProps {
  className?: string;
  placeholder?: string;
  isDesktop?: boolean;
  searchDelay?: number;
  rootElement: HTMLElement
}

const Search = ({
  className = '',
  placeholder = 'Hvad vil du gerne spille?',
  isDesktop = true,
  searchDelay,
  rootElement
}: SearchProps) => {
  const searchComponent = useRef<HTMLDivElement>(null);
  const inputElement = useRef<HTMLInputElement>(null);

  const [isSearchResultsOpen, setIsSearchResultsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [context, setContext] = useState<SearchContext>('normal');
  const isLoggedIn = useIsLoggedIn();

  const dsOverlay = document.querySelector('.ds-overlay');

  const isHidden = useMimicNavigationAnimation({
    element: searchComponent?.current,
    hideWithNavigation: !isSearchResultsOpen,
    enable: !!document.querySelector('.is-desktop-detected')
  });

  const popularSearch = useQuery({
    queryFn: getPopularSearches,
    queryKey: ['popularSearches']
  });

  const recentlyPlayed = useQuery({
    queryKey: ['recentlyPlayed', context],
    queryFn: () => getRecentlyPlayed(),
    enabled: () => isLoggedIn && context === 'superFavorite'
  });

  const searchResult = useSearch({ searchQuery, searchDelay });
  const searchInteractions = useSearchInteraction();

  useEffect(() => {
    document.addEventListener('casino:search:open', handleOpen as () => void);
    document.addEventListener('casino:search:close', handleClose);

    if (dsOverlay) {
      dsOverlay.addEventListener('mousedown', handleClose, true);
    }

    return () => {
      document.removeEventListener('casino:search:open', handleOpen as () => void);
      document.removeEventListener('casino:search:close', handleClose);
      if (dsOverlay) {
        dsOverlay.removeEventListener('mousedown', handleClose, true);
      }
    };
  }, [isSearchResultsOpen]);

  useEffect(() => {
    if (isHidden && !isSearchResultsOpen) {
      handleClose();
    }
  }, [isHidden]);

  useEffect(() => {
    const sessionObject = sessionStorage.getItem(SearchInitStateSessionKey);
    if (!sessionObject) return;

    if (isLoggedIn) {
      sessionStorage.removeItem(SearchInitStateSessionKey);
      const context: SearchContext = JSON.parse(sessionObject);
      sessionStorage.removeItem(SearchInitStateSessionKey);

      setContext(context);
      handleOpen();
    }
  }, [isLoggedIn]);

  const toggleNavigation = (activeSearch: boolean) => {
    const element: HTMLElement | null = document.querySelector('[data-mimic-navigation-animation]');
    const searchModule: HTMLElement | null = document.querySelector('[data-module="Search"]');
    const bottomNavigation: HTMLElement | null = document.querySelector('[data-navigation="bottom"]');
    const topbar: HTMLElement | null = document.querySelector('.js-navigation');
    if (!topbar) return;

    if (activeSearch) {
      document.body.style.setProperty('padding-top', '0');
      topbar.style.setProperty('display', 'none');
      bottomNavigation?.style.setProperty('display', 'none');
      searchModule?.style.setProperty('padding-top', 'var(--navigation-compliance-bar-height, 0rem)');
      if (element) {
        element.style.cssText += `
          transform: none;
          transition-duration: 0ms;
          transition-delay: 0ms;
          padding-top: var(--navigation-compliance-bar-height, 0rem);
          top: 0;
        `;
      }
    } else {
      document.body.style.removeProperty('padding-top');
      topbar.style.removeProperty('display');
      bottomNavigation?.style.removeProperty('display');
      searchModule?.style.removeProperty('padding-top');
      if (element) {
        element.style.removeProperty('transform');
        element.style.removeProperty('transition-duration');
        element.style.removeProperty('transition-delay');
        element.style.removeProperty('padding-top');
        element.style.removeProperty('top');
      }
    }
  };

  const handleClose = () => {
    setSearchQuery('');
    setIsSearchResultsOpen(false);
    setContext('normal');
    document.dispatchEvent(new CustomEvent('overlay:modal:hide'));

    if (!isDesktop) {
      toggleNavigation(false);
      rootElement.classList.remove('!block');
    }
  };

  const handleOpen = (context?: CustomEvent) => {
    if (context?.detail?.context) {
      setContext(context.detail.context);
    }

    if (!isDesktop) {
      toggleNavigation(true);
      rootElement.classList.add('!block');
      document.body.classList.add('no-scroll');
    } else {
      document.dispatchEvent(new CustomEvent('overlay:modal:show'));
    }

    setIsSearchResultsOpen(true);
    inputElement.current?.focus();
  };

  const resultTypeToShow = () => {
    const noSearchResults = !searchResult?.isPending && searchQuery.length >= 2 && searchResult && Array.isArray(searchResult.data) && searchResult.data.length === 0;
    if (noSearchResults) {
      return <>
        {noSearchResults && <SearchResult
          header={'Din søgning gav desværre intet resultat'}
          searchResults={[]}
          searchInteractions={searchInteractions}
          searchContext={context}
          type={'noResult'}
          open={isSearchResultsOpen}
        />}
        <SearchResult
          type={'popular'}
          searchContext={context}
          searchInteractions={searchInteractions}
          header={noSearchResults ? 'Prøv et af vores populære spil' : 'Populære søgninger'}
          searchResults={popularSearch.data ?? []}
          open={isSearchResultsOpen}
        />
      </>;
    }

    if (searchResult && !searchResult.isLoading && !searchResult.isError && Array.isArray(searchResult.data) && searchResult.data.length > 0) {
      return <SearchResult
        type={'results'}
        searchInteractions={searchInteractions}
        searchContext={context}
        header={`Søgeresultat${searchResult.data.length > 1 ? 'er' : ''}`}
        searchResults={searchResult.data as Game[]}
        displayCount={true}
        open={isSearchResultsOpen}
      />;
    }

    if (recentlyPlayed && !recentlyPlayed.isLoading && !recentlyPlayed.isError && Array.isArray(recentlyPlayed.data) && recentlyPlayed.data.length > 0) {
      return <SearchResult
        type={'recent'}
        searchInteractions={searchInteractions}
        searchContext={context}
        header={'Senest spillede'}
        searchResults={recentlyPlayed.data?.slice(0, 12) ?? []}
        open={isSearchResultsOpen}
      />;
    }

    if (searchInteractions && !searchInteractions.games.isLoading && !searchInteractions.games.isError && Array.isArray(searchInteractions.games.data) && searchInteractions.games.data.length > 0) {
      return <SearchResult
        type={'recent'}
        searchInteractions={searchInteractions}
        searchContext={context}
        header={'Seneste søgninger'}
        searchResults={searchInteractions.games.data.slice(0, 12) as Game[]}
        open={isSearchResultsOpen}
      />;
    }

    return <SearchResult
      type={'popular'}
      searchInteractions={searchInteractions}
      searchContext={context}
      header={noSearchResults ? 'Prøv et af vores populære spil' : 'Populære søgninger'}
      searchResults={popularSearch.data ?? []}
      open={isSearchResultsOpen}
    />;
  };

  const [distToTop, setDistToTop] = useState('');
  useEffect(() => {
    setDistToTop(searchComponent.current?.getBoundingClientRect().top + 'px' || '123px');
  }, [searchComponent.current]);

  const [useHighZIndex, setUseHighZIndex] = useState(false);
  const removeHighZ = () => {
    setUseHighZIndex(false);
  };

  useEffect(() => {
    if (isSearchResultsOpen) {
      setUseHighZIndex(true);
    } else {
      searchComponent.current?.addEventListener('transitionend', removeHighZ);
    }

    return () => {
      searchComponent.current?.removeEventListener('transitionend', removeHighZ);
    };
  }, [isSearchResultsOpen]);

  return (
    <div
      className={`is-desktop-detected:fixed px-[--grid-margin] right-0 bg-gold-medium is-desktop-detected:bg-transparent is-desktop-detected:backdrop-blur-xl w-full is-desktop-detected:w-[calc(100%_-_var(--sidemenu-width))] ${useHighZIndex ? 'z-10000' : 'z-[60]'} ${className}
      ${isSearchResultsOpen && !isDesktop ? 'top-0' : 'top-[--distance-to-top]'} is-fullscreen:hidden`}
      style={{
        // @ts-expect-error CSS custom props should be valid
        '--distance-to-top': distToTop
      }}
      ref={searchComponent}
    >
      <div
        className={'relative py-12 px-0 flex justify-between w-full is-desktop-detected:max-w-[--grid-columns-12] mx-auto'}
      >

        <div
          className={'flex relative bg-white pl-12 z-[60] rounded-full items-center duration-500 timing-[cubic-bezier(0.455,_0.030,_0.515,_0.955)] w-full'}
        >
          <Icon
            icon={<SearchIcon/>}
            size={'medium'}
          />
          <input
            ref={inputElement}
            className={'text-16 rounded-r-full p-12 ml-12 peer border-0 border-none focus:border-0 w-full focus:placeholder:opacity-50 placeholder:duration-500 focus-within:outline-none'}
            type='text'
            placeholder={`${context === 'superFavorite' ? 'Find din superfavorit' : context === 'addFavorite' ? 'Find dit favoritspil' : placeholder}`}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onFocus={handleOpen as () => void}
            value={searchQuery}
            onClick={track}
            data-tracking-meta-data={JSON.stringify({
              action: 'activate search',
              category: 'search',
            })}
          />
          <Icon
            icon={<Close/>}
            size={'medium'}
            onClick={() => {
              setSearchQuery('');
              inputElement?.current?.focus();
            }}
            className={'stroke-neutrals-grey-950 cursor-pointer peer-placeholder-shown:hidden p-12'}
          />

        </div>
        <button
          onMouseDown={handleClose}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClose();
            }
          }}
          className={`border-none z-[60] relative bg-transparent text-14 text-neutrals-grey-950 font-base font-bold overflow-hidden duration-500
          ${isSearchResultsOpen ? 'max-w-56 ml-24 px-8 opacity-100' : 'max-w-0 ml-0 px-0 opacity-0'}`}
          data-tracking-meta-data={JSON.stringify({
            action: 'search close',
            category: 'search',
          })}
          onClick={track}
        >LUK
        </button>

        <div
          className={`absolute max-h-screen is-desktop-detected:max-h-[640px] is-desktop-detected:rounded-b-24
          pl-[--grid-margin] pr-[calc(var(--grid-margin)_/_2)] top-0 z-50
          grid mx-auto duration-200 timing-[cubic-bezier(0.455,_0.030,_0.515,_0.955)] bg-transparent
          ${'-left-[--grid-margin] -right-[--grid-margin]'}
          ${isSearchResultsOpen ? 'grid-rows-1 pt-80 h-screen pb-24 is-tablet-detected:pb-48 is-desktop-detected:pb-40 !bg-neutrals-coolGrey-100 is-desktop-detected:h-auto' : 'grid-rows-0 overflow-hidden'}`}
        >
          <div
            className={`search-result-content min-h-0 flex flex-col gap-16 gap-y-32 ${isSearchResultsOpen ? 'pb-28 overflow-auto' : 'pb-0'}`}
            data-tracking-meta-data={JSON.stringify({ category: 'search', searchQuery: searchQuery, searchContext: context, })}
          >
            {
              resultTypeToShow()
            }
          </div>
        </div>
      </div>
    </div>

  );
};

export default Search;
