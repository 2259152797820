import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFavorite } from '../../hooks';
import { getGameData } from '../../helpers/dataAdapter';
import { getGame } from '../../api/CasinoApi';
import { MegaTileLive } from '../../Components/Tiles/MegaTile/MegaTileLive/MegaTileLive';
import { IconButton } from '../../Components/IconButton/IconButton';
import { ShortGame } from '../GameLauncher/GameLauncher';
import useIsLoggedIn from '@SharedScripts/Hooks/useIsLoggedIn';
import { getLoginUrl } from '@SharedScripts/UserData/Authentication';
import { track } from '../../helpers/tracking';

type LiveInformationProps = Pick<ShortGame, 'gameServiceId' | 'minWager'>

const LiveInformationEntry = ({ gameServiceId, minWager } : LiveInformationProps) => {
  const { favorites, addFavorite, removeFavorite } = useFavorite();
  const isFavorite = favorites.includes(gameServiceId);
  const isLoggedIn = useIsLoggedIn();

  const { data, isPending } = useQuery({
    queryKey: ['getGame', gameServiceId],
    queryFn: () => getGame(gameServiceId)
  });

  minWager = `Min. ${minWager}`;

  const favoriteAction = () => {
    if (isLoggedIn) {
      isFavorite ? removeFavorite(gameServiceId) : addFavorite(gameServiceId);
    } else {
      window.location.href = getLoginUrl({});
    }
  };

  const gameData = getGameData(data ? [data] : [], isPending)?.[0];
  const { potSize, playersText, dealerName, liveGamesText } = gameData ?? {};

  return (
    <div className='flex items-center gap-8 pb-10'>
      <div className='hidden sm:block sm:relative z-50' data-tracking-meta-data={JSON.stringify({
        action: 'add to favorites click',
        category: 'liveInformation',
        label: 'add to favorites click',
        gameTitle: gameData?.title,
      })} onClick={track}>
        <IconButton
          variant='favourite'
          action={favoriteAction}
          size='small'
          checked={isFavorite}
        />
      </div>
      <MegaTileLive {...{ potSize, playersText, dealerName, liveGamesText, minWager, isGamePage: true }} />
    </div>
  );
};

export default LiveInformationEntry;
