﻿import React from 'react';
import { Icon } from '../Icon/Icon';
import Checkmark from '../../../assets/icons/svg/Checkmark';
import { filterTypeMap } from '../../hooks/useFilter';
import { track } from '../../helpers/tracking';

export interface CheckboxProps {
  checked?: boolean
  value: string,
  name: keyof typeof filterTypeMap,
  // eslint-disable-next-line no-unused-vars
  onClick: (name: keyof typeof filterTypeMap, value: string) => void,
  showLabel?: boolean
}

const Checkbox = ({ name, value, onClick, checked = false, showLabel = true }: CheckboxProps) => {

  return (
    <div
      onClick={(event) => {
        onClick(name, value);
        track(event, 100);
      }}
      className={'grid cursor-pointer grid-cols-[1fr_auto] duration-100 p-12 hover:bg-neutrals-coolGrey-50 rounded-full'}>
      {showLabel && <label
        id={'checkbox-label-' + name}
        className={'pointer-events-none text-16 leading-tight text-neutral-950 block'}
        htmlFor={value}>{value}</label>}
      <input
        className={'appearance-none peer absolute block'}
        type='checkbox'
        readOnly={true}
        id={value}
        checked={checked}
      />
      <div
        role={'checkbox'}
        aria-checked={checked}
        aria-labelledby={'checkbox-label-' + name}
        className='size-24 duration-[inherit] bg-white rounded-full border border-solid border-neutral-950 self-center
        peer-checked:border-gold-primary peer-checked:*:bg-gold-primary '>
        <div className='place-items-center grid content-center size-24 -translate-x-1 -translate-y-1 duration-[inherit] rounded-full'>
          <Icon
            icon={<Checkmark/>}
            size={'small'}
            className={`stroke-white stroke-2 duration-[inherit] *:duration-[inherit] *:[stroke-dasharray:_2.6rem]
            ${checked ? '*:[stroke-dashoffset:_0]' : '*:[stroke-dashoffset:_-2.6rem]'}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
