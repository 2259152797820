﻿import CategoryList from '../../Components/CategoryList/CategoryList';
import React from 'react';
import ComponentLoader from '@SharedScripts/ComponentLoader';
import QueryState from '@SharedScripts/QueryState';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';

export type ContainerEntryProps = {
  categoryName?: string;
  categoryId: string;
  category: Category;
  defaultSort: DefaultSort;
  showAsList: boolean;
  isEnabledCuratedLayout?: boolean;
}

type DefaultSort = {
  name: string;
  id: string;
}

type Category = {
  id: string;
  expandCategoryId: string;
  title: string;
}

class CategoryListEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);

    if (!this.settings.category) {
      return;
    }

    this.settings.categoryName = this.settings.category.title;
    this.settings.categoryId = this.settings.category.expandCategoryId.toLowerCase();

    this.init();
  }

  init() {
    const queryClient = new QueryState().load('shared');

    const ReactApp = (props: ContainerEntryProps) => {
      return (
        <QueryClientProvider client={queryClient}>
          <CategoryListWrapper {...props}/>
        </QueryClientProvider>
      );
    };

    const root = createRoot(this.element);
    root.render(<ReactApp {...this.settings} />);
  }
}


const CategoryListWrapper = (props: ContainerEntryProps) => {


  return <CategoryList {...props}/>;
};

export default CategoryListEntry;
