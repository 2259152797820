import React, { useState } from 'react';

type PaginationProps = {
  pages: number;
  currentPage: number;
  hidden?: boolean;
}

// This component is only used by crawlers and screen readers, and it is intentionally hidden from users

const Pagination = ({ pages, currentPage, hidden = true }: PaginationProps) => {
  const { protocol, hostname, pathname } = window.location;
  const [isHidden] = useState(hidden);

  return <nav aria-label={'Pagination'} className={isHidden ? 'sr-only' : ''}>
    <ul>
      { currentPage !== 1 &&
      <li>
        <a href={`${protocol}//${hostname}${pathname}?page=${currentPage - 1}`} rel={'prev'}>Forrige side</a>
      </li>
      }
      {Array(pages).fill('').map((_, i) => (
        <li key={`page=${i + 1}`}>
          <a aria-label={`Side ${i + 1}`}
            aria-current={currentPage === i + 1 ? 'page' : 'false'}
            href={`${protocol}//${hostname}${pathname}?page=${i + 1}`}
          >
            {i + 1}
          </a>
        </li>))}
      { currentPage !== pages &&
      <li>
        <a href={`${protocol}//${hostname}${pathname}?page=${currentPage + 1}`} rel={'next'}>Næste side</a>
      </li>
      }
    </ul>
  </nav>;
};

export default Pagination;