import React from 'react';
import ComponentLoader from '@SharedScripts/ComponentLoader';
import QueryState from '@SharedScripts/QueryState';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import WinnersListModule from './WinnersList';

class WinnersListEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);

    this.init();
  }

  init() {
    const queryClient = new QueryState().load('shared');

    const ReactApp = (props: any) => {
      return (
        <QueryClientProvider client={queryClient}>
          <WinnersListModule {...props} maxCount={props.count} />
        </QueryClientProvider>
      );
    };

    const root = createRoot(this.element);
    root.render(<ReactApp {...this.settings} />);
  }
}

export default WinnersListEntry;
