﻿import React from 'react';
import Radio from './Radio';
import { filterTypeMap } from '../../hooks/useFilter';

interface RadioGroupProps {
  filters: string[],
  name: keyof typeof filterTypeMap | 'Sorting',
  variant: 'radio' | 'filterChip'
  // eslint-disable-next-line no-unused-vars
  onValueChange: (name: keyof typeof filterTypeMap | 'Sorting', value: string) => void,
  selectedOption: string
}

const variants = {
  radio: '',
  filterChip: 'grid grid-cols-2 gap-16'
};

const RadioGroup = ({ filters, name, onValueChange, variant = 'radio', selectedOption } : RadioGroupProps) => {

  const variantStyle = variants[variant];

  return (
    <div className={`${variantStyle} my-12`}>
      {filters.map((filter) => {
        return (<Radio
          name={name}
          variant={variant}
          key={filter}
          checked={selectedOption === filter}
          filter={filter}
          onRadioClick={() => onValueChange(name, filter)}
          showLabel={true}/>);
      })}
    </div>);
};

export default RadioGroup;
