import ComponentLoader from '@SharedScripts/ComponentLoader';
import { isTablet, isDesktop } from '@SharedScripts/Utils/DeviceClass';

class GameInformationEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);
    this.init();
  }

  init() {

    const heightRef = document.getElementById('readMoreText');
    let readMoreOpen = false;
    let readMoreBreakAfterLines = 4;

    if (isTablet()) {
      readMoreBreakAfterLines = 3;
    }
    if (isDesktop()) {
      readMoreBreakAfterLines = 6;
    }

    const setOpen = (event: CustomEvent) => {
      event.preventDefault();
      readMoreOpen = !readMoreOpen;

      const readMoreTextElement = document.getElementById('readMoreText');

      if (readMoreTextElement && event.detail.context.element) {
        readMoreTextElement.style.webkitLineClamp = readMoreOpen ? '50' : `${readMoreBreakAfterLines}`;

        event.detail.context.element.getElementsByTagName('span')[0].innerText = readMoreOpen ? 'Vis mindre' : 'Vis mere';

        event.detail.context.element.querySelector('svg > path').setAttribute('d', readMoreOpen ? 'M21 18L16.1412 13L11 18' : 'M21 14L16.1412 19L11 14');
      }
    };

    const setHidden = (hidden: boolean) => {
      if (readMoreOpen) return;

      const element = document.getElementById('readMoreToggleLink');
      if (element) {
        element.style.display = hidden ? 'none' : 'block';
      }
    };

    if (heightRef) {
      setHidden(heightRef.offsetHeight === heightRef.scrollHeight);
      window.addEventListener('resize', () => setHidden(heightRef.offsetHeight === heightRef.scrollHeight), false);

      document.addEventListener('casino:readmorelinkclick', (data) => setOpen(data as CustomEvent));

    }
  }
}

export default GameInformationEntry;
