import React from 'react';
import { GameGraphics } from '../../../GameGraphics/GameGraphics';
import { Header } from '../../../Header/Header';
import { Paragraph } from '../../../Paragraph/Paragraph';
import { TileProps } from '../../tiles';
import { Logo } from '../../../Logo/Logo';
import { useMedia } from '@SharedScripts/Hooks/useMediaQuery';


interface MegaTileNavProps {
  className?: string;
  options: TileProps[];
  active?: number;
  isLoading?: boolean;
  indexedErrors?: boolean[];
  // eslint-disable-next-line no-unused-vars
  setActive: (index: number) => void;
}

export const MegaTileNav = ({ className = '', options, active, isLoading, indexedErrors, setActive }: MegaTileNavProps) => {
  const {isLg} = useMedia();
  if (!options || !options.length) return null;

  return (
    <ul className={`${className} backdrop-blur-[32px] rounded-tr-32 rounded-br-32`}>
      {options.map((game, index) => {

        const { gameGraphics, title, tag } = game ?? {};

        const hasGraphicsError = indexedErrors?.[index];
        const graphicsDisabled = game?.isUnavailable || (game.type !== 'Casino' && game?.isTableClosed);

        const activeHeight = options.length - Number(`0.${options.length}`);
        const passiveHeight = options.length + Number(`0.${options.length / options.length}`);
        const listItemHeight = active === index ? `calc(100%/${activeHeight} - 2px)` : `calc(100%/${passiveHeight})`;

        return (
          <li
            key={index}
            className='list-none transition-all duration-300 ease-in-out border-none'
            style={{ height: listItemHeight }}
          >
            <button
              className={`relative w-full m-0 border-none h-full flex items-center px-12 gap-16 transition-opacity duration-300 ease-in-out backdrop-blur-lg
                text-neutrals-white focus:z-50 focus-visible:ring-primary ${active === index ? 'bg-black/40' : 'bg-black/80 hover:bg-black/40'}
                ${index === 0 ? 'rounded-tr-32' : ''} ${index === options.length - 1 ? 'rounded-br-32' : ''}`}
              onMouseDown={() => setActive(index)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  setActive(index);
                }
              }}
            >

              <div className='relative w-64 h-64 shrink-0'>
                <GameGraphics
                  {...gameGraphics}
                  error={hasGraphicsError}
                  isLoading={isLoading}
                  disabled={graphicsDisabled}
                  disableCache={game.type === 'Live'}
                  useDarkGradient={game.type !== 'Casino' && game?.isTableClosed}
                  useLogoAsForeground={game.type !== 'Live'}
                  rounded='rounded-16'
                />

                {(isLoading || hasGraphicsError || !gameGraphics?.backgroundUrl) && (
                  <Logo className='absolute p-16 -translate-y-2 !fill-neutrals-grey-500' />
                )}
              </div>

              <div className='flex flex-col justify-start align-baseline text-start w-full'>
                {tag && <Paragraph
                  size={isLg ? 14 : 12}
                  weight='medium'
                  className='leading-10 text-neutrals-coolGrey-100'>{tag}</Paragraph>}
                {title && <Header
                  size='2xs'
                  as='h2'
                  className={isLg ? 'lg:text-14' : 'md:!text-12'}
                  italic={true}
                  truncate={isLg ? 2 : 1}>{title}</Header>}
              </div>

            </button>
          </li>
        );
      })}
    </ul>
  );
};
