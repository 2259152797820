﻿import React from 'react';
import Favorites from './Favorites';
import ComponentLoader from '@SharedScripts/ComponentLoader';
import QueryState from '@SharedScripts/QueryState';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';

export type Labels = {
  recentlyPlayed: string;
  yourFavorites: string;
  addFavoritesButton: string;
}

const defaultLabels = {
  recentlyPlayed: 'Senest spillede',
  yourFavorites: 'Dine favoritter',
  addFavoritesButton: 'Tilføj'
} satisfies Labels;

class FavoritesEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);

    this.init();
  }

  init() {
    const queryClient = new QueryState().load('shared');

    const ReactApp = (props: any) => {
      return (
        <QueryClientProvider client={queryClient}>
          <Favorites
            labels={{
              ...defaultLabels,
              ...props.labels
            }}
          />
        </QueryClientProvider>
      );
    };

    const root = createRoot(this.element);
    root.render(<ReactApp {...this.settings} />);
  }
}


export default FavoritesEntry;
