﻿import { QueryFunction, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { logger } from '../helpers/logger';
import { getRelated } from '../api/CasinoApi';
import { Game } from '../apiTypes/GameService';
import { getGameData } from '../helpers/dataAdapter';

const useSliderRelated = (relatedToGameId: string) => {
  const { isPending, isError, data, error, refetch, isRefetching } = useQuery({
    queryKey: ['related', relatedToGameId],
    queryFn: (() => getRelated(relatedToGameId)) as QueryFunction<Game[]>
  });

  const games = getGameData(data || [], isPending);

  useEffect(() => {
    refetch();
  }, [relatedToGameId]);

  const isPendingOrRefetching = isPending || isRefetching;
  if (!games || !games.length) {
    logger(`No related games available ín slider with gamed id: ${relatedToGameId}`);
  }

  return {
    games,
    isPendingOrRefetching,
    isError,
    error
  };
};

export default useSliderRelated;