﻿import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

type DrawerProps = {
  children: React.ReactNode
  isOpen: boolean
}

const Drawer = ({ children, isOpen }: DrawerProps) => {
  useEffect(() => {
    if (isOpen) {
      document.dispatchEvent(new CustomEvent('overlay:modal:show'));
    } else {
      document.dispatchEvent(new CustomEvent('overlay:modal:hide'));
    }
  }, [isOpen]);

  return createPortal(<div className={`theme-casino bg-white font-open-sans font-normal px-12 pt-48 duration-500 fixed top-[100vh] h-auto rounded-t-[40px] 
  md:px-28 md:pt-64 max-h-[calc(100svh_-_64px)] overflow-auto max-w-512 w-full left-1/2 -translate-x-1/2 ease-out z-10000
  ${isOpen ? 'translate-y-[calc(-100%_-_(100vh_-_100dvh))] pointer-events-auto' : 'translate-y-0 pointer-events-none'}`}>
    {children}
  </div>, document.body);
};

export default Drawer;
