﻿import React from 'react';
import Checkmark from '../../../assets/icons/svg/Checkmark';
import { Icon } from '../Icon/Icon';
import { filterTypeMap } from '../../hooks/useFilter';
import { track } from '../../helpers/tracking';

interface ToggleProps {
  title?: string,
  name: keyof typeof filterTypeMap,
  checked: boolean,
  size?: 'small' | 'normal',
  showLabel?: boolean,
  label?: string,
  labelCSSClass?: string,
  className?: string,
  // eslint-disable-next-line no-unused-vars
  onClick: (name: keyof typeof filterTypeMap, value: string) => void
}

const sizes = {
  normal: 'w-64 h-40 *:size-32 peer-checked:*:left-28',
  small: 'w-[45px] h-[30px] *:size-20 peer-checked:*:left-20'
};


const Toggle = ({ title, name, showLabel = false, label, className = '', labelCSSClass = 'text-16 text-neutral-950', checked = false, size = 'normal', onClick = () => { } }: ToggleProps) => {

  const sizeStyles = sizes[size];

  return <div
    onClick={(event) => {
      onClick(name, name);
      track(event, 100);
    }}
    className={`grid items-center grid-cols-[1fr_auto] my-12 p-12 ${className}`}>
    {showLabel && <label
      className={`pointer-events-none ${labelCSSClass} block`}
      htmlFor={name}>{label ? label : title}</label>}
    <input
      className={'appearance-none peer absolute block'}
      type='checkbox'
      readOnly={true}
      id={name}
      checked={checked}
    />
    <div
      className={`relative cursor-pointer rounded-full duration-200 bg-neutrals-coolGrey-100 hover:bg-neutrals-coolGrey-300 *:left-4
      peer-checked:bg-gold-primary is-desktop-detected:hover:peer-checked:bg-gold-medium ${sizeStyles}`}>
      <div
        className={`absolute grid place-items-center duration-[inherit] bg-white rounded-full top-1/2 -translate-y-1/2 ${size === 'small' ? '*:hidden' : ''}`}>
        <Icon
          icon={<Checkmark/>}
          size={'medium'}
          className={`stroke-gold-primary stroke-2 duration-[inherit] *:duration-[inherit] *:[stroke-dasharray:_2.6rem] ${checked ? '*:[stroke-dashoffset:_0]' : '*:[stroke-dashoffset:_-2.6rem] *:opacity-0'}`}
        />
      </div>
    </div>
  </div>;
};

export default Toggle;
