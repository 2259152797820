﻿import React from 'react';
import { Button } from '../../Button/Button';
import { GameActions } from '../../TileActions/GameActions';
import type { TileProps } from '../tiles';
import { SUPERFAVORITE_TILE_BUTTON_NEWTEXT, SUPERFAVORITE_TILE_BUTTON_TEXT } from '../../../helpers/tileHelper';
import { Link } from '../../Link/Link';
import { track } from '../../../helpers/tracking';

export interface SuperFavoriteTileProps {
  superFavoriteSearch: CustomEvent;
  favoriteGame?: TileProps;
  className?: string;
}

const SuperFavoriteTile = ({
  favoriteGame,
  superFavoriteSearch,
  className,
}: SuperFavoriteTileProps) => {
  const {
    title,
    favourited,
    navigationUrl,
    navigationUrlWithLaunchParam,
    primaryButtonCallback,
    tabIndex,
    isTableClosed,
  } = favoriteGame || {};

  const linkAriaLabel = `Start spil ${title}`;

  return (
    <article
      data-tracking-meta-data={JSON.stringify({
        tileType: 'SuperFavorite',
        category: 'Din superfavorit',
        gameTitle: favoriteGame?.title,
      })}
      className={`group @container w-full relative ${className}`}>
      {favoriteGame && (
        <div onClick={track}>
          <Link
            className='absolute top-0 right-0 bottom-0 left-0 focus-visible:ring-primary rounded-32 z-20'
            href={navigationUrl}
            ariaLabel={linkAriaLabel}
            disabled={!navigationUrl}
            tabIndex={tabIndex}
          />
        </div>
      )}

      <div
        className={`relative mb-32 w-full h-144 md:h-full rounded-32 grid md:flex md:flex-col md:justify-center
          ${!favoriteGame ? 'border border-dashed border-gold-primary bg-white place-items-center' : 'overflow-hidden'}`}
      >

        {!favoriteGame && (
          <>
            <div
              data-tracking-meta-data={JSON.stringify({
                label: 'Tilføj',
              })}
              onClick={track}
              className='text-gold-primary font-bold text-18 md:text-20 md:mb-16'>
              Vælg din superfavorit
            </div>
            <Button
              className='absolute bottom-0 translate-y-1/2 md:translate-y-0 md:relative md:!px-64 md:!py-24 md:*:text-12 focus-visible:ring-primary'
              text={SUPERFAVORITE_TILE_BUTTON_TEXT}
              size='medium'
              onClick={() => {
                document.dispatchEvent(superFavoriteSearch);
              }}
            />
          </>
        )}

        {favoriteGame && (
          <>
            <div
              className='grid w-fit z-30 pt-16 pl-24 md:h-full md:pt-32 md:pl-32'
            >
              <button
                className='w-fit h-max bg-white/25 rounded-full uppercase px-16 py-10 border-none text-white text-12 font-extrabold tracking-widest focus-visible:ring-primary'
                onClick={() => {
                  document.dispatchEvent(superFavoriteSearch);
                }}
              >
                {SUPERFAVORITE_TILE_BUTTON_NEWTEXT}
              </button>
            </div>
            <div
              className='grid w-fit z-10 grid-rows-[1fr_auto] pb-28 pl-24 md:h-full md:pl-32 md:pb-40'
            >
              <p className='text-white m-0 mt-16 md:pt-32 text-14 font-bold'>
                Din Superfavorit
              </p>
              <p className='text-white m-0 text-16 md:text-24 font-extrabold italic'>
                {favoriteGame.title}
              </p>
            </div>
            <div
              className='absolute inset-0 blur-xl'
              style={{
                backgroundImage: `url('${favoriteGame.gameGraphics?.backgroundUrl}')`,
                backgroundSize: 'cover',
              }}
            ></div>
            <div
              className='absolute -right-28 -rotate-20 rounded-8 delay-75 duration-300 ease-out overflow-hidden size-128 top-10 group-hover:-rotate-[5deg] group-hover:right-0
                md:size-176 md:@384:size-240 md:-right-96 md:top-20'
            >
              <img
                src={favoriteGame.gameGraphics?.portraitUrl}
                className='max-w-full'
                alt=''
              />
            </div>
          </>
        )}
      </div>
      {favoriteGame && (
        <GameActions
          href={navigationUrlWithLaunchParam}
          linkAriaLabel={linkAriaLabel}
          favourited={favourited}
          favouritedAriaLabel={`Føj ${title} til favouritter`}
          primaryButtonCallback={primaryButtonCallback}
          className='absolute bottom-6 md:bottom-0 self-center left-1/2 -translate-x-1/2 md:translate-y-1/2 z-30 gap-12'
          tabIndex={tabIndex}
          isTableClosed={isTableClosed}
        />
      )}
    </article>
  );
};

export default SuperFavoriteTile;
