﻿import React from 'react';
import { track } from '../../helpers/tracking';

export interface RadioProps {
  checked: boolean
  name: string,
  filter: string,
  // eslint-disable-next-line no-unused-vars
  onRadioClick: (filter: string) => void,
  showLabel?: boolean
  variant: 'radio' | 'filterChip'
}

const variants = {
  radio: 'grid grid-cols-[1fr_auto] hover:bg-neutrals-coolGrey-50 rounded-full my-12',
  filterChip: 'grid place-content-center border border-solid border-neutral-950 rounded-20 hover:bg-neutrals-coolGrey-50 ' +
  'has-[:checked]:bg-gold-primary has-[:checked]:border-gold-primary ' +
  'is-desktop-detected:hover:has-[:checked]:bg-gold-medium is-desktop-detected:hover:has-[:checked]:border-gold-medium'
};

const Radio = ({ name, filter, onRadioClick, checked = false, showLabel = true, variant = 'radio' }: RadioProps) => {

  const variantStyle = variants[variant];

  const label = variant === 'filterChip' ? `Op til ${filter.replaceAll('.', ',')} kr.` : filter;

  return (
    <div className={`group ${variant} cursor-pointer`}>
      <div
        className={`${variantStyle} duration-75 p-12`}
        onClick={(event) => {
          onRadioClick(filter);
          track(event, 100);
        }}>
        {showLabel && <label
          className={`text-16 block cursor-pointer pointer-events-none lining-nums ${checked && variant === 'filterChip' ? 'text-white' : 'text-neutral-950'} ${variant === 'radio' ? 'text-left' : ''}`}
          htmlFor={filter}>{label}</label>}
        <input
          className={'appearance-none peer absolute block'}
          type='radio'
          id={filter}
          checked={checked}
          readOnly={true}
          name={name}
          value={filter}
        />
        {variant === 'radio' &&
          <div
            className='size-24 duration-[inherit] bg-white rounded-full border border-solid
          peer-checked:border-gold-primary border-neutral-950 grid place-items-center *:scale-0 peer-checked:*:scale-100'>
            <div className='size-14 bg-gold-primary duration-[inherit] rounded-full'></div>
          </div>
        }
      </div>
    </div>
  );
};

export default Radio;
