import React from 'react';
import { Header } from '../../Components/Header/Header';
import { Game, GameTypes } from '../../apiTypes/GameService';

export type GameDetailsProps = Pick<Game, 'type' | 'minWager'> & {
  provider: string;
  paybackPercentageFormatted: string;
  gameTypeText: string;
}

const GameDetailsEntry = (props: GameDetailsProps) => (
  <div className='grid lg:grid-cols-4 grid-cols-2 gap-16'>
    <div className='rounded-16 bg-white h-128 p-16 md:p-24 order-0'>
      <Header as='h3' size='xs' className='text-gold-primary font-normal'>Udbyder</Header>
      <div className='text-gold-primary font-bold text-18 md:text-20'>{props.provider}</div>
    </div>
    <div className={`rounded-16 bg-white h-128 p-16 md:p-24 ${props.type === GameTypes.CASINO ? 'order-1' : 'order-2'}`}>
      <Header as='h3' size='xs' className='text-gold-primary font-normal'>Minimum indsats</Header>
      <div className='text-gold-primary font-bold text-18 md:text-20'>{props.minWager}</div>
    </div>
    {props.type === GameTypes.CASINO &&
      <div className='rounded-16 bg-white h-128 p-16 md:p-24 order-2'>
        <Header as='h3' size='xs' className='text-gold-primary font-normal'>Tilbagebetaling</Header>
        <div className='text-gold-primary font-bold text-18 md:text-20'>{props.paybackPercentageFormatted}</div>
      </div>
    }
    <div className={`rounded-16 bg-white h-128 p-16 md:p-24 ${props.type === GameTypes.CASINO ? 'order-3' : 'order-1'}`}>
      <Header as='h3' size='xs' className='text-gold-primary font-normal'>Spiltype</Header>
      <div className='text-gold-primary font-bold text-18 md:text-20'>{props.gameTypeText}</div>
    </div>
  </div>
);

export default GameDetailsEntry;
