﻿import React from 'react';
import { Header } from '../Header/Header';
import { DynamicTile } from '../Tiles/DynamicTile';
import { type TileProps, TileTypesEnum } from '../Tiles/tiles';
import { getTileBorder, getTileStyle } from '../../helpers/styleHelper';
import {getCasinoPathname, getLoadingTiles} from '../../helpers/tileHelper';
import { Link } from '../Link/Link';
import { ExpandCategory } from '../../modules/Sliders/Slider.entry';
import { toUrlFriendlyName } from '../../helpers/stringConverter';
import { isDesktop } from '@SharedScripts/Utils/DeviceClass';
import { track } from '../../helpers/tracking';

export interface ContainerProps {
  sectionName: string;
  className?: string;
  games: TileProps[];
  isLoading?: boolean;
  expandCategory?: ExpandCategory;
}

const SmallTileList = ({ sectionName, className = '', games, isLoading, expandCategory }: ContainerProps) => {
  const showLoadingState = isLoading && (!games || games?.length === 0);
  if (showLoadingState) games = getLoadingTiles();

  const pathName = getCasinoPathname();

  return (
    <section className={`min-h-480 md:min-h-565 w-[--grid-columns-12] mx-auto block pb-32 ${className}`}>
      <div className='flex items-center justify-between w-full mb-12'>
        <Header
          as='h2'
          size='base'>
          {sectionName ?? ''}
        </Header>

        {
          expandCategory && games?.at(0)?.type && <div data-tracking-meta-data={JSON.stringify({
            action: 'see all click',
            label: 'Se alle',
            category: sectionName ?? ''
          })} onClick={track}>
            <Link
              className={'font-base text-14 font-bold text-neutrals-grey-950'}
              href={`/${pathName}/${games[0].type === 'Live' ? 'live' : ''}/kategori/${toUrlFriendlyName(expandCategory.title)}`}
            >Se alle</Link>
          </div>
        }
      </div>

      <div className='grid gap-y-16 md:gap-y-24 gap-x-40  lg:gap-y-32 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 overflow-y-clip
      is-desktop-detected:gap-x-0 is-desktop-detected:md:grid-cols-1 is-desktop-detected:lg:grid-cols-2 is-desktop-detected:xl:grid-cols-3
      '>
        {games.map((tileProps, i) => {
          return (
            <div className={`*:size-full ${getTileStyle(i)}`} key={`${tileProps.id}-${i}`}>

              <DynamicTile
                className={`h-full ${getTileStyle(i)} ${!isDesktop() ? 'p-0 mt-2 is-desktop-detected:mt-0' : ''} ${getTileBorder()}`}
                key={`${tileProps.id}-${i}`}
                type={TileTypesEnum.SMALL}
                data={{ ...tileProps, isLoading }}
              />
            </div>);
        })}
      </div>
    </section>
  );
};

export default SmallTileList;
