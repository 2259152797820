import React, { useCallback, useEffect, useState } from 'react';
import { GameGraphics } from '../../GameGraphics/GameGraphics';
import { Link } from '../../Link/Link';
import SliderIndicator from '../../Sliders/SliderIndicator';
import { useSlide } from '../../../hooks/useSlide';
import { MegaTileBody } from './MegaTileBody/MegaTileBody';
import { MegaTileNav } from './MegaTileNav/MegaTileNav';
import { TileProps } from '../tiles';
import { Backdrop } from '../../Backdrop/Backdrop';
import { Badge } from '../../Badge/Badge';
import { track } from '../../../helpers/tracking';
import {isMobile, isTablet} from '@SharedScripts/Utils/DeviceClass';
import useBreakpoint from '@SharedScripts/Hooks/useBreakpoint';
import { CATEGORY_TILE_TAGLINE } from '../../../helpers/tileHelper';
import { MegaTileInitIndex } from '../../../Constants';

export interface MegaTileProps {
  games: TileProps[];
  className?: string;
  lobbyName: string;
  isLoading: boolean;
}

export const MegaTile = ({ games, isLoading, className = '', lobbyName }: MegaTileProps) => {
  const [graphicsLoading, setGraphicsLoading] = useState(true);
  const [graphicsError, setGraphicsError] = useState<boolean[]>([]);
  const [gamesLength, setGamesLength] = useState(0);
  const { active, setActive, handleTouchStart, handleTouchEnd } = useSlide(gamesLength);
  const breakpoint = useBreakpoint();
  const slicedGames: TileProps[] = isLoading ? [{}] : games.slice(0, (breakpoint === 'xs' || breakpoint === 'sm') ? games.length : 6);
  const activeGame = games[active];
  const isContentLoading = !graphicsError.length && (isLoading || graphicsLoading);
  const navigationUrl = activeGame?.isUnavailable ? '' : activeGame?.navigationUrl;
  const bannerInfo = activeGame?.bannerInfo;

  const handleGraphicsError = (index: number) => {
    setGraphicsError((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = true;
      return newErrors;
    });
  };

  useEffect(() => {
    setInitialIndex();
  }, [lobbyName, games]);

  const setInitialIndex = useCallback(() => {
    let index = active;
    const sessionObject = sessionStorage.getItem(MegaTileInitIndex);
    if (sessionObject) {
      const title = JSON.parse(sessionObject);
      const foundIndex = slicedGames.findIndex((game) => game.title === title);
      if (foundIndex !== -1) {
        index = foundIndex;
        sessionStorage.removeItem(MegaTileInitIndex);
      }
    }

    setActive(index);
  }, [lobbyName, slicedGames]);

  if (!isLoading && !gamesLength && gamesLength !== games.length) {
    setGamesLength(slicedGames.length);
  }

  return (
    <section className='mb-24 pt-24 is-desktop-detected:pt-0' data-tracking-meta-data={JSON.stringify({
      category: activeGame?.tag,
      value: active + 1,
      gameTitle: activeGame?.title,
      tileType: 'Mega'
    })}
    >

      <div
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        className={`lg:max-w-[--grid-columns-12] group relative h-352 md:h-480 mx-auto mt-4 ml-4 ${className}`}
      >
        {!isContentLoading && bannerInfo &&
          <Badge text={bannerInfo.text} fontColor={bannerInfo.fontColor} backgroundColor={bannerInfo.backgroundColor}/>
        }
        <div className='absolute inset-0' data-tracking-meta-data={JSON.stringify({
          action: isMobile() || isTablet() ? 'click game tile' : 'game open',
          label: isMobile() || isTablet() ? 'mobile' : 'desktop',
          category: activeGame?.tag,
          value: active + 1,
          gameTitle: activeGame?.title,
          tileType: 'Mega'
        })}
        onClick={track}
        >
          <Link
            className='absolute inset-0 z-20 pointer-events-auto focus:outline-none focus-visible:ring-primary rounded-32'
            href={navigationUrl}
            tabIndex={-1}
            aria-label={`Start spil ${activeGame?.title}`}
            disabled={activeGame?.isUnavailable || !activeGame?.navigationUrl || isContentLoading}
          />
        </div>

        {slicedGames.map((game, index) => {
          const isActive = index === active;
          const graphicsDisabled = game?.isUnavailable || (game.type !== 'Casino' && (game?.isTableClosed && !game.liveStreamLink));
          const activeBackground = activeGame?.gameGraphics?.backgroundUrl;
          const shouldRenderLogo = isContentLoading || (graphicsError?.[active] && !graphicsDisabled) || !activeBackground;
          const identifier = `megatile-${index}`;

          return (
            <React.Fragment key={`${identifier}`}>

              {!isContentLoading && !graphicsError?.[active] && activeBackground &&
                <Backdrop
                  src={activeBackground}
                  className={`pointer-events-none ${isActive ? 'opacity-100' : 'opacity-0'}`}
                />
              }

              <GameGraphics
                {...game?.gameGraphics}
                isLoading={isActive ? isContentLoading : false}
                onLoaded={isActive ? () => setGraphicsLoading(false) : undefined}
                disabled={isActive && graphicsDisabled}
                liveStreamLink={game?.tag !== CATEGORY_TILE_TAGLINE ? game?.liveStreamLink : ''}
                onError={() => handleGraphicsError(index)}
                imageWidth={isMobile() ? 400 : undefined}
                disableCache={game?.type === 'Live'}
                error={graphicsError?.[index] || false}
                className='top-0'
                rounded='rounded-32'
                foregroundStyle={`transition-transform duration-700 ease-mega !origin-bottom opacity-0 z-10 pointer-events-none 
                left-1/2 !h-full !w-auto
                ${isActive
              ? '-translate-x-1/2 scale-[1.05] opacity-100'
              : `md:-translate-x-32 ${active < index
                ? (active === 0 && index === slicedGames.length - 1)
                  ? '-translate-x-320'
                  : 'translate-x-320'
                : (active === slicedGames.length - 1 && index === 0)
                  ? 'translate-x-320'
                  : '-translate-x-320'
              }`
            }`}
                backgroundStyle={game?.liveStreamLink && game?.tag !== CATEGORY_TILE_TAGLINE
                  ? 'h-full'
                  : `relative transition-all duration-500 ease-in-out rounded-32 group-hover:scale-110 z-10 ${isActive ? 'opacity-100' : 'opacity-0'} ${game?.type === 'Live' ? 'object-top' : 'object-fill md:max-w-2/3 md:rounded-r-none'}`}
              />

              <MegaTileBody
                {...game}
                index={index}
                isActive={isActive}
                isLoading={isContentLoading}
                navigationUrl={navigationUrl}
                graphicsError={graphicsError?.[index] || false}
                showLogo={shouldRenderLogo}
              />

            </React.Fragment>
          );
        })}

        {!isContentLoading && (
          <MegaTileNav
            options={slicedGames}
            active={active}
            indexedErrors={graphicsError}
            isLoading={isContentLoading}
            setActive={setActive}
            className='absolute top-0 right-0 z-30 flex-col hidden w-1/3 h-full md:flex'
          />
        )}
      </div>

      <div className='relative flex items-center justify-center w-full mt-48 bg-neutrals-coolGrey-100 md:hidden'>
        {gamesLength > 1 &&
          <SliderIndicator
            length={gamesLength}
            active={active}
            setActive={setActive}
          />
        }
      </div>
    </section>
  );
};
