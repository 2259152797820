﻿import React from 'react';
import { MegaTile } from '../../Components/Tiles/MegaTile/MegaTile';
import useLobbyTile from '../../hooks/useLobbyTile';
import { GameTypes } from '../../apiTypes/GameService';
import ComponentLoader from '@SharedScripts/ComponentLoader';
import QueryState from '@SharedScripts/QueryState';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';

export interface MegaTileEntryProps {
  lobbyName: string,
  className?: string
}

class MegaTileEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);

    if (!this.settings.lobbyName) {
      this.settings.lobbyName = window.location.pathname.includes('/live') ? 'Live' : 'Casino';
    }

    this.init();
  }

  init() {
    const queryClient = new QueryState().load('shared');

    const ReactApp = (props: any) => {
      return (
        <QueryClientProvider client={queryClient}>
          <MegaTileWrapper lobbyName={props.lobbyName} />
        </QueryClientProvider>
      );
    };

    const root = createRoot(this.element);
    root.render(<ReactApp {...this.settings} />);
  }
}


const MegaTileWrapper = ({ lobbyName, className = '' }: MegaTileEntryProps) => {
  const gameType = Object.entries(GameTypes).find(([_, v]) => v === lobbyName)?.[1];
  const lobby = useLobbyTile(gameType ?? GameTypes.CASINO) ?? {};

  return <MegaTile
    lobbyName={lobbyName}
    games={lobby.games}
    isLoading={lobby.isPendingOrRefetching}
    className={className}
  />;
};

export default MegaTileEntry;
