import React, { useState } from 'react';
import { Button } from '../../Components/Button/Button';
import { AddCoins, Close, CloseFullscreen, Fullscreen } from '../../../assets/icons/svg';
import { Icon } from '../../Components/Icon/Icon';
import { PaymentLink, ShortGame } from './GameLauncher';
import { isDesktop, isFullscreen, isMobile } from '@SharedScripts/Utils/DeviceClass';
import { removeParameter } from '@SharedScripts/Utils/Url';
import { track } from '../../helpers/tracking';
import { isGuid } from '../../Types';

interface GameLauncherHeaderProps {
  game: ShortGame,
  gameTitle: string,
  paymentLink?: PaymentLink,
  returnUrl?: string,
  toggleFullscreen: () => void,
  isLoggedIn?: boolean
}

interface HeaderControlsProps {
  returnUrl?: string,
  paymentLink?: PaymentLink,
  isLoggedIn?: boolean,
}

const GameLauncherHeader = ({ gameTitle, paymentLink, returnUrl, toggleFullscreen, isLoggedIn = false }: GameLauncherHeaderProps) => {

  const [fullscreen, setFullscreen] = useState(false);

  const changeIcon = () => {
    setFullscreen(isFullscreen());
  };

  const closeAndRedirectToReturnUrl = () => {
    if (returnUrl && !(returnUrl.startsWith('#') && isGuid(returnUrl?.substring(1)))) {
      window.location.href = returnUrl;
    } else {
      window.location.href = removeParameter('launch');
    }
  };

  const RightHeaderControls = ({ paymentLink }: HeaderControlsProps) => {
    if (isMobile()) {
      return <div className={`landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:mb-50 ${isLoggedIn ? 'cursor-pointer' : ''}`}>
        <Icon className='h-30 w-30 fill-white' icon={<AddCoins />}  onClick={() => { window.location.href = paymentLink?.url ?? ''; }}/>
      </div>;
    }

    if (!isDesktop) {
      return <Button className='' variant='primary' size='small' onClick={() => { window.location.href = paymentLink?.url ?? ''; }} text={paymentLink?.text ?? ''}></Button>;
    }

    return (
      <div className={`flex items-center self-center gap-10 ${isLoggedIn ? 'cursor-pointer' : ''}`}>
        <Button className='' variant='primary' size='small' onClick={() => { window.location.href = paymentLink?.url ?? ''; }} text={paymentLink?.text ?? ''}></Button>
      </div>
    );
  };

  const LeftHeaderControls = () => {
    if (isDesktop() && fullscreen) { // if fullscreen
      return <div className={`flex items-center self-center gap-10 ${isLoggedIn ? 'cursor-pointer' : ''}`} onClick={() => { toggleFullscreen(); changeIcon(); }}>
        <Icon
          icon={<CloseFullscreen/>}
          size={'medium'}
          className={'stroke-white text-white 4 p-6'}
        />
        <div className='mt-2 text-12'>Luk fuld skærm</div>
      </div>;
    } else if (isDesktop() && !fullscreen) { // if not fullscreen, show close
      return <div className={`flex items-center self-center gap-10 ${isLoggedIn ? 'cursor-pointer' : ''}`} onClick={(e) => { toggleFullscreen(); changeIcon(); track(e); }} data-tracking-meta-data={JSON.stringify({
        action: 'fuld skaerm',
        category: 'gamelauncher',
        label: 'Fuld skærm',
        gameTitle: gameTitle
      })}
      >
        <Icon
          icon={<Fullscreen/>}
          size={'medium'}
          className={'stroke-white text-white 4 p-6'}
        />
        <div className='mt-2 text-12'>Fuld skærm</div>
      </div>;
    } else {
      return <div className={`flex items-center self-center gap-10 landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:flex-col ${isLoggedIn ? 'cursor-pointer' : ''}`} onClick={() => closeAndRedirectToReturnUrl()} >
        <Icon
          icon={<Close/>}
          size={'medium'}
          className={'stroke-white text-white 4 p-6'}
        />
        <div className='mt-2 text-12 landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:text-center'>Luk spil</div>
      </div>;
    }
  };

  return <div
    className={`bg-transparent h-[51px] w-full flex justify-between text-white items-center py-6 px-32 sm:absolute ${!isLoggedIn ? 'is-desktop-detected:pointer-events-none is-desktop-detected:bg-black is-desktop-detected:opacity-50' : ''}
    group-[.is-fullscreen]:group-[.is-mobile-detected]:bg-transparent  group-[.is-fullscreen]:group-[.is-mobile-detected]:relative is-mobile-detected:h-full is-mobile-detected:pt-48 landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:flex-col`}>
    <LeftHeaderControls/>
    <div data-tracking-meta-data={JSON.stringify({
      action: 'deposit click',
      category: 'gamelauncher',
      label: paymentLink?.text ?? '',
      gameTitle: gameTitle
    })}
    onClick={track}
    >
      <RightHeaderControls paymentLink={paymentLink}/>
    </div>
  </div>
  ;
};

export default GameLauncherHeader;
