﻿import React, { useEffect, useState } from 'react';
import {
  formatCountDown,
  POT_OF_GOLD_OPENING_HOUR,
  TimeRemaining,
  updateRemainingTime
} from '../../helpers/tileHelper';
import { Header } from '../../Components/Header/Header';

const getOpeningDay = () => {
  const today = new Date();
  const day = today.getHours() < POT_OF_GOLD_OPENING_HOUR ? today : new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  return new Date(day.getFullYear(), day.getMonth(), day.getDate(), POT_OF_GOLD_OPENING_HOUR).toString();
};

const Countdown = (props: any) => {
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining | 'closed' | null>(null);
  const isClosed = timeRemaining === 'closed' || !timeRemaining;

  const openingTime = getOpeningDay();

  useEffect(() => {
    setTimeRemaining(updateRemainingTime(openingTime));
    const interval = setInterval(() => setTimeRemaining(updateRemainingTime(openingTime)), 1000);

    return () => clearInterval(interval);
  }, [openingTime]);

  return <Header as='h2' className='md:text-24 lg:text-32 text-gold-ultraLight tabular-nums' truncate={2}>
    {`Vindes senest om: ${isClosed ? '- Lukket' : formatCountDown(timeRemaining)}`}
  </Header>;
};

export default Countdown;