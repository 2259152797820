﻿import React from 'react';
import { Asset, GameTypes } from '../../apiTypes/GameService';
import Slider from '../../Components/Sliders/Slider';
import { TileTypesEnum } from '../../Components/Tiles/tiles';
import { ShortGame } from '../GameLauncher/GameLauncher';
import { useQuery } from '@tanstack/react-query';
import { getGame } from '../../api/CasinoApi';

type GameExperienceProps = Pick<ShortGame, 'type' | 'gameServiceId'>

const renderCasinoExperince = (images: Asset[]) => (
  !!images.length && <Slider type={TileTypesEnum.ASSET} sectionName='Spiloplevelse' games={[]} assets={images} />
);

const renderLiveExperience = (video?: Asset) => {
  const heightClass = 'h-224 md:h-448 lg:h-512';
  return video && video.url && <div className='grid grid-cols-1 relative'>
    <div className={`w-full ${heightClass}`} />
    <iframe id='videoPlayer' className={`absolute w-full bg-black border-0 rounded-32 ${heightClass}`} src={video.url} allow='autoplay' allowFullScreen />
  </div>;
};

const isVideoAsset = (mime?: string | null) => (mime && mime.indexOf('video') >= 0);

const GameExperience = ({ type, gameServiceId }: GameExperienceProps) => {
  const { data } = useQuery({
    queryKey: ['getGame', gameServiceId],
    queryFn: () => getGame(gameServiceId)
  });

  if (type.name === GameTypes.CASINO) {
    return renderCasinoExperince(data?.graphics?.assets?.filter((asset) => !isVideoAsset(asset.mimeType)) ?? []);
  } else {
    return renderLiveExperience(data?.graphics?.assets?.find((asset) => isVideoAsset(asset.mimeType)));
  }
};

export default GameExperience;
