﻿import React, { useEffect, useState, MouseEvent } from 'react';
import MissingData from '../../Components/Favorites/MissingData';
import { useFavorite } from '../../hooks';
import Slider from '../../Components/Sliders/Slider';
import { TileProps, TileTypesEnum } from '../../Components/Tiles/tiles';
import { useQuery } from '@tanstack/react-query';
import { getGames, getRecentlyPlayed } from '../../api/CasinoApi';
import { Header } from '../../Components/Header/Header';
import { DynamicTile } from '../../Components/Tiles/DynamicTile';
import { getGameData } from '../../helpers/dataAdapter';
import SuperFavoriteTile from '../../Components/Tiles/SuperFavoriteTile/SuperFavoriteTile';
import { Labels } from './Favorites.entry';
import useIsLoggedIn from '@SharedScripts/Hooks/useIsLoggedIn';
import { Icon } from '../../Components/Icon/Icon';
import ChevronUp from '../../../assets/icons/svg/ChevronUp';
import ChevronDown from '../../../assets/icons/svg/ChevronDown';
import { useMedia } from '@SharedScripts/Hooks/useMediaQuery';
import { track } from '../../helpers/tracking';
import { SearchContextEnum } from '../../Types';

type FavoriteProps = {
  labels: Labels
}

const Favorites = ({ labels }: FavoriteProps) => {
  const openFavoriteSearchEvent = new CustomEvent('casino:search:open', { detail: { context: SearchContextEnum.AddFavorite } });
  const openSuperSearchEvent = new CustomEvent('casino:search:open', { detail: { context: SearchContextEnum.SuperFavorite } });
  const isLoggedIn = useIsLoggedIn();
  const { isMd, isLg } = useMedia();
  const { favorites: favoritesIds, highlightsIds } = useFavorite();

  const favorites = useQuery({
    queryKey: ['getGames', 'favorites', ...favoritesIds],
    queryFn: () => getGames(favoritesIds),
    enabled: () => Boolean(favoritesIds.length)
  });

  const superFavorite = useQuery({
    queryKey: ['getGames', 'superFavorites', ...highlightsIds],
    queryFn: () => getGames(highlightsIds),
    enabled: () => Boolean(highlightsIds.length)
  });

  const recentlyPlayed = useQuery({
    queryKey: ['recentlyPlayed'],
    queryFn: () => getRecentlyPlayed(),
    enabled: () => isLoggedIn
  });

  const adaptedSuperFavorite = getGameData(superFavorite.data || [], superFavorite.isPending)[0];
  const adaptedFavorites = getGameData(favorites.data || [], favorites.isPending).filter((game) => game.id !== adaptedSuperFavorite?.id);
  const adaptedRecentlyPlayed = getGameData(recentlyPlayed.data?.slice(0, 12) || [], recentlyPlayed.isPending);

  const [open, setOpen] = useState(false);
  const enableExpand = (isMd && !isLg && adaptedFavorites.length > 2) || (isLg && adaptedFavorites.length > 4);

  const toggleExpand  = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (open) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    setOpen(!open);
  };

  useEffect(() => {
    favorites.refetch();
  }, [favoritesIds, highlightsIds]);

  return <section className={'mx-auto pt-24 is-desktop-detected:pt-0'}
    data-tracking-meta-data={JSON.stringify({
      category: 'dine favoritter',
    })}>
    <div className={'w-full flex flex-col items-center md:flex-col-reverse'}>

      {isLoggedIn && !!adaptedRecentlyPlayed.length && (<>
        {isMd && (
          <div className={'w-full pb-28'}>
            <Header
              as={'h2'}
              size={'sm'}
              className={'mb-16'}>{labels.recentlyPlayed}</Header>
            <div className={'w-full md:grid md:grid-cols-4 lg:grid-cols-6 gap-y-40 gap-x-[--grid-gutter] pb-28'}>
              {adaptedRecentlyPlayed.map((game) => (
                <DynamicTile
                  key={game.id}
                  type={TileTypesEnum.MEDIUM}
                  data={game}
                  className={'w-[--grid-columns-3] lg:w-[--grid-columns-2]'}
                />
              ))}
            </div>
          </div>
        )}
        {!isMd && (
          <Slider
            sectionName={labels.recentlyPlayed}
            type={TileTypesEnum.MEDIUM}
            games={adaptedRecentlyPlayed}
            className={'mb-32'}
          />
        )}
      </>
      )}
      {!isLoggedIn &&
      <MissingData type={'notLoggedIn'}/>}
      {isLoggedIn &&
      <div className={'w-full md:mb-40'}>

        <div className={'grid mb-8 grid-cols-[1fr_auto] grid-rows-[repeat(2,_auto)] w-full'}>
          {(!!favoritesIds.length || !!highlightsIds.length) &&
            <>
              <Header
                as={'h2'}
                size={'sm'}>{labels.yourFavorites}</Header>
              <button
                className={'bg-transparent border-none text-14 text-neutrals-grey-950 w-fit font-bold'}
                onClick={(e) => {
                  document.dispatchEvent(openFavoriteSearchEvent);
                  track(e);
                }}
                data-tracking-meta-data={JSON.stringify({
                  action: 'add favorites',
                  category: 'dine favoritter',
                  label: labels.addFavoritesButton,
                })}
              >
                {labels.addFavoritesButton}
              </button>
            </>
          }
          {!favoritesIds.length && !highlightsIds.length &&
            <MissingData
              customEvent={openFavoriteSearchEvent}
              type={'noFavorites'}
              className={'col-span-2 mx-auto'}/>}
        </div>
        {
          (!!favoritesIds.length || !!highlightsIds.length) &&
          <div className={'w-full flex flex-col md:grid md:grid-cols-4 lg:grid-cols-6 gap-y-16 md:gap-y-40 gap-x-[--grid-gutter] pb-28'}>
            <SuperFavoriteTile
              className={'col-span-2'}
              superFavoriteSearch={openSuperSearchEvent}
              favoriteGame={adaptedSuperFavorite || null}/>
            {adaptedFavorites.slice(0, enableExpand && !open ? (isLg ? 4 : 2) : adaptedFavorites.length).map((game: TileProps, index: number) => {
              game.onClick = () => { favorites.refetch(); };
              return <div key={`${game.id}-${index}`} data-tracking-meta-data={JSON.stringify({
                gameTitle: game.title,
              })}><DynamicTile
                  key={`${game.title}-${game.id}`}
                  type={isMd ? TileTypesEnum.MEDIUM : TileTypesEnum.SMALL}
                  data={game}
                /></div>;
            })}
          </div>
        }
        {enableExpand &&
          <div className={'grid mb-8 grid-rows-[repeat(2,_auto)] justify-center w-full'}>
            <div className="inline-flex">
              <button onClick={(e) => {
                toggleExpand(e);
                track(e);
              }}
              data-tracking-meta-data={JSON.stringify({
                action: open ? 'see less click' : 'see all click',
                label: open ? 'Vis færre' : 'Vis alle',
              })}
              className='flex items-center text-14 leading-[2.8rem] font-bold text-neutrals-grey-950 focus-within:outline-none bg-transparent border-none'
              >
                {`${open ? 'Vis færre' : 'Vis alle'}`}<Icon icon={open ? <ChevronUp /> : <ChevronDown />} size='large' className='align-bottom' />
              </button>
            </div>
          </div>
        }
      </div>
      }
    </div>
  </section>;
};

export default Favorites;
