﻿import { QueryFunction, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getCategoryWinners, getGameWinners } from '../api/CasinoApi';
import { Winner } from '../apiTypes/GameService';
import { getWinnerData } from '../helpers/dataAdapter';
import { GAME_TITLE_MISSING } from '../helpers/tileHelper';

const useWinnersFeedGame = (elementId: string, gameServiceId?: string, categoryId?: string, count: number = 9) => {
  const { isPending, isError, data, error, refetch } = useQuery({
    queryKey: ['winners', categoryId ?? gameServiceId],
    queryFn: (() => (categoryId && getCategoryWinners(categoryId, count)) || (gameServiceId && getGameWinners(gameServiceId, count))) as QueryFunction<Winner>,
    refetchInterval: 0,
    refetchIntervalInBackground: true
  });

  useEffect(() => {
    refetch();
  }, [gameServiceId, categoryId]);

  const winners = getWinnerData(data, elementId, isPending);

  return {
    title: data?.winnersTitle ?? GAME_TITLE_MISSING,
    winners,
    isPendingOrRefetching: isPending,
    isError,
    error
  };
};

export default useWinnersFeedGame;
