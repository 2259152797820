import { useState } from 'react';
import { getParameter } from '@SharedScripts/Utils/Url';

const useQueryParameterChange = (queryKey: string) => {
  const [locationChanged, setLocationChanged] = useState(getParameter(queryKey));

  const handleEvent = (event: CustomEvent | any) => {
    if (event.detail.queryKey === queryKey && event.detail.queryValue !== locationChanged) {
      setLocationChanged(event.detail.queryValue ?? event.detail.queryKey);
    }
  };

  document.addEventListener('danskespil:locationChanged', handleEvent);
  return locationChanged;
};

export default useQueryParameterChange;
