import React from 'react';
import WinnersList from '../../Components/WinnersList/WinnersList';
import useWinnersFeedGame from '../../hooks/useWinnersFeedGame';
import { useMedia } from '@SharedScripts/Hooks/useMediaQuery';
import { WINNERS_LIST_PAGING_SIZE } from '../../helpers/tileHelper';
import { PotOfGoldProps } from '../../Types';

export type ContainerEntryProps = PotOfGoldProps & {
  gameServiceId?: string,
  expandCategoryId?: string,
  elementId: string,
  maxCount: number,
}

const WinnersListModule = ({ maxCount, isPotOfGold, gameServiceId, expandCategoryId, elementId }: ContainerEntryProps) => {
  const { isLg } = useMedia();
  if (!gameServiceId && !expandCategoryId) return;

  const screenAmount = isLg && WINNERS_LIST_PAGING_SIZE < maxCount ? WINNERS_LIST_PAGING_SIZE : maxCount;
  const { title, winners, isPendingOrRefetching } = useWinnersFeedGame(elementId, gameServiceId, expandCategoryId, screenAmount) ?? {};

  return <WinnersList
    title={title}
    className='md:pb-20'
    winners={winners}
    amount={maxCount}
    isPotOfGold={isPotOfGold}
    isLoading={isPendingOrRefetching}
  />;
};

export default WinnersListModule;
