import React from 'react';

const GameLoader = (props: any) => {
  return <div className='video max-w-600 mx-auto my-16'>
    <div className='aspect-video relative'>
      <iframe src='https://player.vimeo.com/video/@Model.VimeoID?h=fee6fc1f95&title=0&byline=0&portrait=0'
        className='absolute top-0 left-0 w-full h-full'
        allow='autoplay; fullscreen; picture-in-picture'
        allowFullScreen></iframe>
    </div>
    <script src='https://player.vimeo.com/api/player.js'></script>
  </div>;
};

export default GameLoader;




