﻿import useSliderCategory from '../../hooks/useSliderCategory';
import React from 'react';
import SmallTileList from '../../Components/SmallTileList/SmallTileList';
import ComponentLoader from '@SharedScripts/ComponentLoader';
import QueryState from '@SharedScripts/QueryState';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';

export type ExpandCategory = {
  expandCategoryId: string,
  title: string
}

interface SmallTileListProps {
  categoryId: string;
  sectionName: string;
  sectionId: string;
  expandCategory?: ExpandCategory;
  elementId: string;
}

class SmallTileListEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);

    if (!this.settings.categoryName) {
      this.settings.categoryName = 'Kun hos Danske Spil';
    }

    this.init();
  }

  init() {
    const queryClient = new QueryState().load('shared');

    const ReactApp = (props: any) => {
      const smallTileList : SmallTileListProps = {
        categoryId: props.section.parentCategoryId as string,
        sectionName: props.section.sectionTitle as string,
        sectionId: props.section.sectionId as string,
        elementId: props.elementId,
      };

      if (props.section.expandCategory) {
        smallTileList.expandCategory = {
          expandCategoryId: props.section.expandCategory.expandCategoryId,
          title: props.section.expandCategory.title,
        };
      }

      return (
        <QueryClientProvider client={queryClient}>
          <SmallTileListWrapper {...smallTileList} />
        </QueryClientProvider>
      );
    };

    const root = createRoot(this.element);
    root.render(<ReactApp {...this.settings} />);
  }
}


const SmallTileListWrapper = ({ sectionName, categoryId, sectionId, expandCategory, elementId }: SmallTileListProps) => {
  const { games, isLoading, sectionName: sectionNameFromApi } = useSliderCategory(sectionName, categoryId, sectionId, elementId) ?? {};

  return <SmallTileList
    sectionName={sectionNameFromApi}
    games={games}
    isLoading={isLoading}
    expandCategory={expandCategory}
  />;
};

export default SmallTileListEntry;
