﻿import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteSearchInteractions, getSearchInteraction, postSearchInteraction } from '../api/CasinoApi';
import useIsLoggedIn from '@SharedScripts/Hooks/useIsLoggedIn';

const useSearchInteraction = () => {
  const queryClient = useQueryClient();
  const isLoggedIn = useIsLoggedIn();

  const addSearchInteraction = useMutation({
    mutationFn: (gameId: string) => postSearchInteraction(gameId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['searchInteractions'] });
    }
  });

  const removeSearchInteraction = useMutation({
    mutationFn: deleteSearchInteractions,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['searchInteractions'] });
    }
  });

  const games = useQuery({
    queryFn: () => getSearchInteraction(),
    queryKey: ['searchInteractions'],
    enabled: isLoggedIn
  });

  return {
    addSearchInteraction,
    removeSearchInteraction,
    games
  };
};

export type UseSearchInteractionReturnType = ReturnType<typeof useSearchInteraction>
export default useSearchInteraction;
