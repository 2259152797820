﻿import ComponentLoader from '@SharedScripts/ComponentLoader';

class TopNavigationCenterEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);
    this.init();
  }

  init() {
    const centerItem = (item: HTMLElement) => {
      const containerWidth = this.settings.rootElement.offsetWidth;
      const itemWidth = item.offsetWidth;

      // Calculate item's offset position within the container
      const itemOffsetLeft = item.offsetLeft;

      // Calculate the scroll position to center the item
      const scrollPosition = itemOffsetLeft - (containerWidth / 2) + (itemWidth / 2);

      // Smoothly scroll the container to the calculated position
      this.settings.rootElement.scrollTo({
        left: scrollPosition,
        behavior: 'instant',
      });
    };

    // Center the active item on page load
    const activeItem = this.settings.rootElement.querySelector('ul li.bg-gold-primary') as HTMLElement;
    if (activeItem) {
      centerItem(activeItem);
    }

    // Remove the opacity-0 class from the container
    this.settings.rootElement.classList.remove('opacity-0');
  }
}

export default TopNavigationCenterEntry;
