﻿import { QueryFunction, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { logger } from '../helpers/logger';
import { getGames, getLobbyTiles } from '../api/CasinoApi';
import { Game, GameTypes, LobbyTile, LobbyTileType } from '../apiTypes/GameService';
import { getLobbyGameData, mapLobbyGameData, overrideGameData } from '../helpers/dataAdapter';
import useFavorite from './useFavorite';
import useIsLoggedIn from '@SharedScripts/Hooks/useIsLoggedIn';

const useLobbyTile = (lobbyType: GameTypes) => {
  const isLoggedIn = useIsLoggedIn();

  const { highlightsIds } = useFavorite();
  const superFavorite = useQuery({
    queryKey: ['games', 'superFavorites', ...highlightsIds],
    queryFn: (() => !!highlightsIds.length && getGames(highlightsIds)) as QueryFunction<Game[]>,
    enabled: !!highlightsIds.length
  });

  const lobby = useQuery({
    queryKey: ['lobby', lobbyType, isLoggedIn],
    queryFn: (() => getLobbyTiles(lobbyType)) as QueryFunction<LobbyTile[]>
  });

  const gameIds = lobby.data?.flatMap((l) => (l.tileType === LobbyTileType.GAME && l.id ? l.id : [])) || [];
  const { isPending, isError, data, error, refetch, isRefetching } = useQuery({
    queryKey: ['games', lobbyType, ...gameIds],
    queryFn: (() => !!gameIds.length && getGames(gameIds)) as QueryFunction<Game[]>,
    enabled: !!gameIds.length
  });

  const isPendingOrRefetching = isPending || isRefetching;
  const gameData = lobby.data?.filter((l) => l.tileType !== LobbyTileType.CHANCE).map((lobbyData) => {
    switch (lobbyData.tileType) {
    case LobbyTileType.GAME:
      return overrideGameData(lobbyData, data?.find((g) => g.id === lobbyData.id));
    case LobbyTileType.SUPER_FAVORITE:
      return superFavorite.data?.length ? overrideGameData(lobbyData, superFavorite.data[0]) : mapLobbyGameData(lobbyData);
    case LobbyTileType.CAMPAIGN:
    case LobbyTileType.CATEGORY:
      return mapLobbyGameData(lobbyData);
    default:
      break;
    }
  }) || [];

  const games = getLobbyGameData(gameData.map((game) => game ?? {}), isPendingOrRefetching);

  useEffect(() => {
    if (gameIds.length) refetch();
  }, [lobbyType]);

  useEffect(() => {
    superFavorite.refetch();
  }, [highlightsIds]);

  useEffect(() => {
    if (isLoggedIn) return;
    lobby.refetch().then(() => refetch());
  }, [isLoggedIn]);

  if (!games || !games.length) {
    logger(`No games available in lobby with type: ${lobbyType}`);
  }

  return {
    games,
    isPendingOrRefetching,
    isError,
    error
  };
};

export default useLobbyTile;
