﻿import ComponentLoader from '@SharedScripts/ComponentLoader';

class MimicNavigationAnimationEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);
    if (document.querySelector('.is-desktop-detected')) return;
    this.init();
  }

  init() {
    const element: HTMLElement | null = document.querySelector('[data-mimic-navigation-animation]');
    const navigation = document.querySelector('.js-navigation');
    const sidemenu = document.querySelector('#js-casino-sidemenu');

    if (!element || !navigation) return;

    const showClass = ['delay-200', 'duration-[450ms]', 'translate-y-0', 'timing-[cubic-bezier(0.455,_0.030,_0.515,_0.955)]'];
    const hideClass = ['delay-100', 'duration-500', '-translate-y-[--move-by]',  'timing-[cubic-bezier(0.455,_0.030,_0.515,_0.955)]'];

    const top = navigation.getBoundingClientRect().bottom;

    const moveBy = top + element.getBoundingClientRect().height;

    element.style.setProperty('--top', top + 'px');
    element.style.setProperty('--move-by', moveBy + 'px');

    const handleScroll = () => {
      if (sidemenu && navigation) {
        // if navigation has class ds-navigation--scroll-up"
        if (navigation.classList.contains('ds-navigation--scroll-down')) sidemenu.classList.add('!top-50');
        else sidemenu.classList.remove('!top-50');
      }
    };

    const hideSearch = () => {
      element.classList.add(...hideClass);
      element.classList.remove(...showClass);
    };

    const showSearch = () => {
      element.classList.remove(...hideClass);
      element.classList.add(...showClass);
    };

    document.addEventListener('navigation:scroll', handleScroll);
    document.addEventListener('navigation:casino:hide', hideSearch);
    document.addEventListener('navigation:casino:show', showSearch);
  }
}

export default MimicNavigationAnimationEntry;
