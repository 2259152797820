﻿import React from 'react';
import RadioGroup from '../FormElements/RadioGroup';
import { Header } from '../Header/Header';
import Drawer from '../Drawer';
import { Icon } from '../Icon/Icon';
import Close from '../../../assets/icons/svg/Close';

type DropdownProps = {
  sortingOptions: string[];
  isOpen: boolean
  selectedSort: string;
  categoryName: string;
  // eslint-disable-next-line no-unused-vars
  handleSortChange: (name: string, newSort: string) => void;
}

const SortingDropdown = ({ sortingOptions, isOpen, selectedSort, handleSortChange, categoryName }: DropdownProps) => {
  const isDesktop = () => Boolean(document.querySelector('.is-desktop-detected'));

  const handleClose = () => {
    document.dispatchEvent(new CustomEvent('overlay:modal:hide'));
    isOpen = false;
  };

  return <div data-tracking-meta-data={JSON.stringify({
    action: 'sort',
    label: selectedSort
  })}>
    {isDesktop() &&
      <div className={`bg-white font-open-sans font-normal h-auto left-1/2 -translate-x-1/2 ease-out pt-0 absolute z-50 w-240 top-full 
  rounded-32 border border-solid border-neutrals-grey-950 shadow-md px-12 duration-100
  ${isOpen ? 'opacity-100 translate-y-8 pointer-events-auto' : 'opacity-0 -translate-y-16 pointer-events-none'}`}
      >
        <RadioGroup filters={sortingOptions} name={'Sorting'} variant={'radio'} onValueChange={handleSortChange}
          selectedOption={selectedSort}
        />
      </div>
    }
    {!isDesktop() &&
      <Drawer isOpen={isOpen}>
        <div data-tracking-meta-data={JSON.stringify({
          action: 'sort',
          label: selectedSort,
          category: categoryName
        })}>
          <div
            className={'relative pb-24 mb-24 after:absolute after:h-1 after:w-[calc(100%_-_24px)] after:left-12 after:bottom-0 after:bg-neutrals-coolGrey-100'}
          >
            <Header as={'h1'} size={'sm'} className={'pl-12'}>Sortering</Header>
            <button onClick={handleClose}
              className={'sticky z-10 mr-8 float-right -translate-y-full inline-block top-0 p-0 size-32 rounded-full border-none bg-neutrals-grey-200'}
            ><Icon icon={<Close/>} size={'medium'} className={'*:stroke-black'}/></button>
          </div>
          <RadioGroup filters={sortingOptions} name={'Sorting'} variant={'radio'} onValueChange={handleSortChange} selectedOption={selectedSort}/>
        </div>
      </Drawer>
    }
  </div>;

};

export default SortingDropdown;
