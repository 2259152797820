﻿import { useEffect, useState } from 'react';

interface useFollowNavigationProps {
  element: HTMLDivElement | null
  hideWithNavigation?: boolean
  enable?: boolean
}

const useMimicNavigationAnimation = ({ element, hideWithNavigation = true, enable = true } : useFollowNavigationProps): boolean => {
  const [isHidden, setIsHidden] = useState(false);
  const [moveBy, setMoveBy] = useState(0);
  const sidemenu = document.querySelector('#js-casino-sidemenu');
  const navigation = document.querySelector('.js-navigation');

  const showClass = ['delay-300', 'duration-[450ms]', 'translate-y-0', 'timing-[cubic-bezier(0.455,_0.030,_0.515,_0.955)]'];
  const hideClass = ['delay-0', 'duration-500', '-translate-y-[--move-by]',  'timing-[cubic-bezier(0.455,_0.030,_0.515,_0.955)]'];

  const hideSearch = () => {
    setIsHidden(true);
    element?.classList.add(...hideClass);
    element?.classList.remove(...showClass);
  };

  const showSearch = () => {
    setIsHidden(false);
    element?.classList.remove(...hideClass);
    element?.classList.add(...showClass);
  };

  useEffect(() => {
    if (document.querySelector('.ds-navigation--scroll-down')) {
      hideSearch();
    }
  }, [element]);

  useEffect(() => {
    if (!element || moveBy || !enable) return;

    let move = element.getBoundingClientRect().top;

    if (hideWithNavigation) {
      move += element.getBoundingClientRect().height;

      if (isHidden) {
        move += parseInt(getComputedStyle(element).marginTop, 10);
      }
    }

    setMoveBy(move);

    element.style.setProperty('--move-by', move + 'px');

    // element.classList.add(...showClass);
    const handleScroll = () => {
      if (sidemenu && navigation) {
        // if navigation has class ds-navigation--scroll-up"
        if (navigation.classList.contains('ds-navigation--scroll-down')) sidemenu.classList.add('!top-50');
        else sidemenu.classList.remove('!top-50');
      }
    };

    document.addEventListener('navigation:scroll', handleScroll);
    document.addEventListener('navigation:casino:hide', hideSearch);
    document.addEventListener('navigation:casino:show', showSearch);

    return () => {
      // document.removeEventListener('navigation:scroll', handleScroll);
      // document.removeEventListener('navigation:casino:hide', hideSearch);
      // document.removeEventListener('navigation:casino:show', showSearch);
    };
  }, [element, hideWithNavigation]);

  return isHidden;
};

export default useMimicNavigationAnimation;
